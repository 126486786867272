import store from '@/store';

interface Permission {
  ID: number;
  name: string;
  end_date: Date | string;
  start_date: Date | string;
}

function foundPermissionRedaction() {
  const permissions = store.getters.profile?.permissions;

  const foundPermission = permissions.find((permission: Permission) => permission.name.includes('redaction'));

  return foundPermission;
}

export default function getPermissionRedaction() {
  return !!foundPermissionRedaction();
}
