
















































































import { Component, Vue, Prop } from 'vue-property-decorator';

import ModalRedactionRejected from '@/components/Redaction/Modals/ModalRedactionRejected.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import { Redaction } from '@/globalInterfaces/Redaction';

const MESSAGE_REDACTION_NOT_SENT = 'Infelizmente você não enviou sua redação ao realizar o primeiro dia de simulado.';
const TITLE_REDACTION_NOT_SENT = 'Redação não enviada';
const TITLE_REDACTION_REJECTED = 'Redação recusada';

const STYLE_BUTTON_CORRECTION = 'ex-button ex-button-primary-inverse';

const STATUS_REDACTION_PENDENT = 'PENDENT';
const STATUS_REDACTION_CORRECTED = 'CORRECTED';
const STATUS_REDACTION_REJECTED = 'REJECTED';

@Component({
  components: {
    ModalRedactionRejected,
    FeedbackUser
  }
})
export default class MyPerformanceRedaction extends Vue {
  @Prop() titleBox!: string;
  @Prop() redaction!: Redaction;
  @Prop({ default: STYLE_BUTTON_CORRECTION }) styleButtonCorrection!: string;
  @Prop({ default: STYLE_BUTTON_CORRECTION }) styleButtonCorrectionDetail!: string;

  private activeModal = new ActiveModal();

  get titleModalRedactionCorretion() {
    return this.redaction?.rejection_cause ? TITLE_REDACTION_REJECTED : TITLE_REDACTION_NOT_SENT;
  }

  get correctionMessageDetail() {
    return this.redaction?.rejection_cause
      ? this.redaction.rejection_cause
      : MESSAGE_REDACTION_NOT_SENT;
  }

  get statusRedaction() {
    return {
      STATUS_REDACTION_PENDENT,
      STATUS_REDACTION_CORRECTED,
      STATUS_REDACTION_REJECTED
    };
  }

  showModal() {
    this.activeModal.activeModal('modalRedactionRejected');
  }

  goToMyRedactionCorrection() {
    this.$router.push({
      name: 'CorrectionRedaction',
      params: {
        redactionId: this.redaction.id
      }
    });
  }
}
