const Course = {
  state: {
    filesVideos: [],
    filesArchives: [],
    filesExercices: []
  },
  mutations: {
    setFilesVideos(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.filesVideos = [...state.filesVideos, ...payload];
    },
    setFilesArchives(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.filesArchives = [...state.filesArchives, ...payload];
    },
    setFilesExercices(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.filesExercices = [...state.filesExercices, ...payload];
    }
  },
  getters: {
    filesVideos: (state: Record<string, any>) => state.filesVideos,
    filesArchives: (state: Record<string, any>) => state.filesArchives,
    filesExercices: (state: Record<string, any>) => state.filesExercices
  }
};

export default Course;
