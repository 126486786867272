

















import {
  Vue, Component, Prop, Watch 
} from 'vue-property-decorator';

const DEFAULT_COLOR_BORDER = 'var(--neutralcolor-low-pure)';

@Component
export default class Checkbox extends Vue {
  @Prop({ default: DEFAULT_COLOR_BORDER }) borderColor!: string;
  @Prop() name!: string;
  @Prop() checked!: boolean;
  @Prop({ required: false }) setCheckbox!: Function;
  @Prop() index!: number;
  @Prop({ default: false }) disabled!: boolean;

  private checkValue = !!this.checked;

  @Watch('checked')
  updateChecked() {
    this.checkValue = this.checked;
  }

  get checkboxStyle() {
    return this.borderColor ? { border: `2px solid ${this.borderColor}` } : {};
  }
}
