import { Route } from 'vue-router';

export function removeIsFreeQueryFromUrl(to: Route) {
  if (!(to.query && to.query.isFree)) return;

  const timer = setTimeout(() => {
    const newUrl = `${window.location.pathname}${window.location.search.replace(
      /(\?|&)isFree=[^&]*/i,
      ''
    )}`;

    window.history.replaceState(null, '', newUrl);

    clearInterval(timer);
  }, 1000);
}
