

































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import Select from '@/components/Input/Select/Select.vue';

import ActiveModal from '@/share/Util/ActiveModal';

import CoursesService from '@/services/Courses/CoursesService';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { Course, CourseClass } from '@/globalInterfaces/Course';

import { ALL_OPTIONS_ID, LIST_OPTIONS } from './constants';

@Component({
  components: {
    Modal,
    Select
  }
})
export default class CourseTutorial extends Vue {
  private idCourse = '';
  private languageSelectId: number | null = null;

  private listOptions = LIST_OPTIONS;

  private listClassCourse: Array<Record<string, any>> = [];

  private setModal = new ActiveModal();
  private coursesService = new CoursesService();

  created() {
    this.$Bubble.subscribe('modalCourse', this.openModal);
  }

  async openModal(event: any) {
    this.idCourse = event.idCourse ? event.idCourse : '';

    this.handleGoCourse();
    await this.setClassCourse(this.idCourse);
  }

  handleGoCourse() {
    this.setModal.activeModal('ModalCourseClass');
  }

  async setClassCourse(idCourse: string) {
    const classes = await this.coursesService.getCourseClasses(Number(idCourse));

    if (classes && classes.length) {
      this.listClassCourse = classes.map((classItem: CourseClass) => ({
        value: classItem.id,
        title: classItem.title
      }));
    }
  }

  async setValue(optionSelected: number) {
    this.languageSelectId = optionSelected;
  }

  async handleStartCourse() {
    try {
      const { idCourse } = this;

      const course = await this.getCourse(Number(idCourse));

      const languageId = this.languageSelectId === ALL_OPTIONS_ID ? null : Number(this.languageSelectId);

      const lastClassCourse = this.listClassCourse.pop();

      const response = await this.coursesService.saveCourseClassPreference(
        Number(idCourse),
        lastClassCourse?.value,
        languageId
      );

      if (response.status === STATUS_RESPONSE_API.SUCCESS_CREATED) {
        this.$router.push({
          path: `/cursos/${course?.slug}/${idCourse}/${lastClassCourse?.value}`
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getCourse(idCourse: number) {
    const courses = await this.coursesService.getCourses();

    const foundCourse = courses.find((course: Course) => course.idCourses === idCourse);

    if (foundCourse) return foundCourse;

    return {};
  }
}
