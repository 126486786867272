


































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LogoConquistae extends Vue {
  @Prop({ default: '260' }) width!: string;
  @Prop({ default: '31' }) height!: string;
}
