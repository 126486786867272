














import { Component, Vue, Prop } from 'vue-property-decorator';

const DEFAULT_FILL = 'var(--neutralcolor-low-dark)';

@Component
export default class Close extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
}
