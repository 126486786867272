const Historic = {
  state: {
    historicCheckbox: [],
    courseAccessWeek: null
  },
  mutations: {
    setHistoricCheckbox(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.historicCheckbox = payload;
    },
    setCourseAccessWeek(state: Record<string, any>, payload: Record<string, any>) {
      state.courseAccessWeek = payload;
    }
  },
  getters: {
    historicCheckbox: (state: Record<string, any>) => state.historicCheckbox,
    courseAccessWeek: (state: Record<string, any>) => state.courseAccessWeek
  }
};

export default Historic;
