


























import { Component, Vue, Prop } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import ActiveModal from '@/share/Util/ActiveModal';

const ID_ERROR = 'error';

@Component({
  components: {
    Modal
  }
})
export default class ModalReportProblemFeedback extends Vue {
  @Prop() isSuccessful!: boolean;

  private modalInstance = new ActiveModal();

  private data = {
    success: {
      id: 'success',
      src: 'woohooo.svg',
      alt: 'Woohooo',
      description: 'O seu problema foi relatado com sucesso.',
      textButton: 'Entendi'
    },
    error: {
      id: 'error',
      src: 'Ooops.svg',
      alt: 'Ooops',
      description: 'Algo deu errado! Não foi possível relatar seu problema. Tente novamente.',
      textButton: 'Tentar novamente'
    }
  };

  get infoModal() {
    if (this.isSuccessful) return this.data.success;

    return this.data.error;
  }

  handleClick() {
    if (this.infoModal.id !== ID_ERROR) return;

    this.modalInstance.activeModal('modalReportProblem');
  }
}
