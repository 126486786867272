import './class-component-hooks';
import Vue from 'vue';
import VCalendar from 'v-calendar';
import VueYoutube from 'vue-youtube';

import responsive from '@/share/Util/Responsive/Responsive';
import mouseUtil from '@/share/Util/Mouse/Mouse';
import sidebar from '@/share/Util/Sidebar/Sidebar';
import breadcrumb from '@/share/Util/Breadcrumb/Breadcrumb';
import Bubble from '@/share/Util/Bubble/Bubble';
import '@/share/Util/Metrics/Metrics';
import { featureToggle } from '@/share/Util/FeatureToggle';
import { COLORS } from './share/Util/Color/PlatformColors';

import App from './App/App.vue';
import router from './router';
import store from './store';

import '@/directives';
import '@/filters';

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV === 'development';
/* Shims-vue.d.ts */
Vue.prototype.$responsive = responsive.getInstance();
Vue.prototype.$mouse = mouseUtil.getInstance();
Vue.prototype.$sidebar = sidebar.getInstance();
Vue.prototype.$breadcrumb = breadcrumb.getInstance();
Vue.prototype.$Bubble = Bubble.getInstance();
Vue.prototype.$featureToggle = featureToggle;
Vue.prototype.$colors = COLORS;

Vue.use(VCalendar);
Vue.use(VueYoutube);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
