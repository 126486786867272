




























import { Component, Vue, Prop } from 'vue-property-decorator';

import { capitalize } from 'lodash';

import LogoExplicae from '@/assets/imgs/logo/LogoExplicae.vue';

import Podium from '../Podium/index.vue';

import { RankingMonthLocal } from '@/globalInterfaces/Gamification';

@Component({
  components: {
    Podium,
    LogoExplicae
  }
})
export default class UserPodium extends Vue {
  @Prop({ required: true }) userData!: RankingMonthLocal;
  @Prop({ required: true }) month!: string;
  @Prop({ default: false }) showLogo!: boolean;
  @Prop({ default: true }) showAnimation!: boolean;

  get date() {
    return `${capitalize(this.month)}/${new Date().getFullYear()}`;
  }
}
