
















import { Vue, Component, Prop } from 'vue-property-decorator';

interface ListInfo {
  title: string;
  quantity: number;
}

@Component
export default class SubjectInfo extends Vue {
  @Prop({ required: false }) listInfo!: Array<ListInfo>;

  handleClick() {
    this.$emit('info-click');
  }
}
