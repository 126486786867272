import {
  IOption,
  IRelateColumnsAnswers,
  IRelateColumnsAlternative,
  ISummationAlternative
} from '@/globalInterfaces/Questions';

interface SummationQuestion {
  answer: number | null;
  rightAnswer: number | null;
}

const Question = {
  state: {
    summationQuestion: {
      shuffledAlternatives: [],
      answer: null,
      rightAnswer: null
    },
    relateColumnsQuestion: {
      alternatives: [],
      shuffledAlternatives: [],
      answers: []
    },
    contentSubjectiveQuestion: ''
  },
  mutations: {
    setShuffledAlternatives(state: Record<string, any>, payload: ISummationAlternative[]) {
      state.summationQuestion.shuffledAlternatives = payload;
    },
    setSummationQuestion(state: Record<string, any>, payload: SummationQuestion) {
      state.summationQuestion = {
        ...state.summationQuestion,
        ...payload
      };
    },
    setAlternativesRelateColumns(state: Record<string, any>, payload: IOption[]) {
      state.relateColumnsQuestion.alternatives = payload;
    },
    setShuffledAlternativesRelateColumns(
      state: Record<string, any>,
      payload: IRelateColumnsAlternative[]
    ) {
      state.relateColumnsQuestion.shuffledAlternatives = payload;
    },
    setAnswersRelateColumnsQuestion(state: Record<string, any>, payload: IRelateColumnsAnswers[]) {
      state.relateColumnsQuestion.answers = payload;
    },
    setContentSubjectiveQuestion(state: Record<string, any>, payload: string) {
      state.contentSubjectiveQuestion = payload;
    }
  },
  getters: {
    summationQuestion: (state: Record<string, any>) => state.summationQuestion,
    shuffledAlternativesSummation: (state: Record<string, any>) => state.summationQuestion.shuffledAlternatives,
    alternativesRelateColumns: (state: Record<string, any>) => state.relateColumnsQuestion.alternatives,
    shuffledAlternativesRelateColumns: (state: Record<string, any>) => state.relateColumnsQuestion.shuffledAlternatives,
    answersRelateColumns: (state: Record<string, any>) => state.relateColumnsQuestion.answers,
    contentSubjectiveQuestion: (state: Record<string, any>) => state.contentSubjectiveQuestion
  }
};

export default Question;
