import { foundPermission, validatePermissionDate } from './Permission';
import { ID_MATERIAL_CATEGORY_FOR_PERMISSION } from '@/constant/videos';

function hasPermissionVideos(video: Record<string, any>) {
  const listIdPermission = ID_MATERIAL_CATEGORY_FOR_PERMISSION[video.IDMaterialCategory];

  const hasPermissionIdVideo = listIdPermission?.some((permissionName: string) => {
    const permission = foundPermission(permissionName);

    if (!permission) return false;

    return validatePermissionDate(permission);
  });

  return hasPermissionIdVideo;
}

export { hasPermissionVideos };
