import store from '@/store';

import AppClient from '../AppClient';
import { Simulated as ISimulated } from './ISimulatedService';
import ActionDataSimulated from './ActionsDataSimulated';

export default class SimulationService {
  private basePath = process.env.VUE_APP_SIMULATION_API;

  private Client = new AppClient({ url: this.basePath });

  async getSimulated(idCategory: number, permissionSaveGetDataStore: boolean) {
    const URI = `/simulations/${idCategory}`;
    const { infoSimulatedQuestion } = store.getters;

    if (
      infoSimulatedQuestion
      && infoSimulatedQuestion.idCategory === idCategory
      && permissionSaveGetDataStore
    ) return infoSimulatedQuestion;

    const response = await this.Client.get(URI);

    if (permissionSaveGetDataStore) {
      store.commit('saveInfoSimulatedQuestion', {
        ...response?.data,
        idCategory
      });
    }

    return response?.data;
  }

  async checkSimulated(idCategory: number) {
    const URI = `/simulation-logs/category/${idCategory}/days`;
    const { simulatedTitleCover } = store.getters;

    const response = await this.Client.get(URI);

    if (!simulatedTitleCover.title && !simulatedTitleCover.coverImage) {
      const actionDataSimulated = new ActionDataSimulated();
      store.commit('saveSimulatedTitleCover', {
        title: actionDataSimulated.formattedNameSimulatedForRoute(
          response?.data?.topId?.URL,
          response?.data?.topId?.title
        ),
        coverImage: actionDataSimulated.cutCoverImage(response?.data?.topId?.coverImage),
        url: response?.data?.topId?.URL
      });
    }

    store.commit('saveInfoSimulationLog', response?.data);

    return response?.data;
  }

  async getListSimulations() {
    const URI = '/simulations/categories';
    const { listSimulations } = store.getters;

    if (listSimulations && listSimulations[0]?.id) {
      return listSimulations;
    }

    const response = await this.Client.get(URI);
    store.commit('saveListSimulations', response?.data);

    return response?.data;
  }

  async getListCourseSimulator(query?: Array<number>) {
    let URI = '/sisu/courses';

    if (query && query.length) URI = `${URI}?id=${query.join(',')}`;

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getListQuotes() {
    const URI = '/sisu/quotes';
    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getQuestions(idQuestion: Array<number>) {
    const URI = `/simulations/questions/[${idQuestion}]`;
    const response = await this.Client.get(URI);

    return response.data;
  }

  async saveSimulated(dataSimulated: ISimulated) {
    const URI = '/simulation-logs';
    const response = await this.Client.post(URI, dataSimulated);

    return response;
  }

  async getChildrenSimulated(idSimulated: number) {
    const URI = `/simulations/categories/${idSimulated}`;
    const response = await this.Client.get(URI);
    return response?.data;
  }

  async getResultSimulated(idSimulated: string) {
    let URI = '/simulations/categories';

    if (!idSimulated) URI = `${URI}/results`;
    else URI = `${URI}/${idSimulated}/results`;

    const { infoSimulatedResult } = store.getters;

    if (infoSimulatedResult.length) return infoSimulatedResult;
    const response = await this.Client.get(URI);

    store.commit('saveInfoSimulatedResult', response?.data);
    return response;
  }

  async saveQuestionAnswered(questionId: number) {
    const URI = '/questions/credits/answered';

    await this.Client.post(URI, { questionId });
  }
}
