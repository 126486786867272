






















import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { NAME_ROUTES_STORE } from '@/router/routes/Store';

@Component({
  components: {
    Modal
  }
})
export default class ModalPlanUpdate extends Vue {
  get title() {
    return this.$store.getters.modalPlanUpdate.title;
  }

  get description() {
    return this.$store.getters.modalPlanUpdate.description;
  }

  handleRedirectPlan() {
    this.$router.push({
      name: NAME_ROUTES_STORE.STORE
    });
  }
}
