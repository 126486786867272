




import { Component, Vue, Watch } from 'vue-property-decorator';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

interface ToastData {
  status?: string;
  text?: string;
  style?: object;
  duration?: number;
  onClick?: Function;
}

const STYLE_DEFAULT = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '2.5rem',
  padding: '1.5rem',
  borderRadius: '0.313rem',
  color: '#212529',
  lineHeight: '1.2'
};

@Component({})
export default class ExToastify extends Vue {
  private toastData: ToastData = {};

  get Toast() {
    return this.$store.state.Toast.data;
  }

  setNotificationToggle(data: ToastData = {}) {
    this.$nextTick(() => {
      Toastify({
        close: true,
        style: {
          ...this.toastData.style,
          ...STYLE_DEFAULT
        },
        text: this.toastData.text || data.text,
        ...data
      }).showToast();
    });
  }

  mapperToast(toast: ToastData) {
    this.toastData = {};

    switch (toast.status) {
      case 'success':
        this.toastData = {
          text: toast.text || 'Operação realizada com sucesso!',
          style: {
            backgroundColor: 'var(--feedbackcolor-success-light)'
          },
          ...toast
        };
        break;
      case 'info':
        this.toastData = {
          style: {
            backgroundColor: 'var(--feedbackcolor-info-light)'
          },
          ...toast
        };
        break;
      case 'warning':
        this.toastData = {
          style: {
            backgroundColor: 'var(--feedbackcolor-warning-light)'
          },
          ...toast
        };
        break;
      case 'error':
        this.toastData = {
          text: toast.text || 'Aconteceu um erro!',
          style: {
            backgroundColor: 'var(--feedbackcolor-error-light)'
          },
          ...toast
        };
        break;
      default:
        this.toastData = {
          ...toast
        };
    }
  }

  @Watch('Toast')
  setToast(newToast: ToastData) {
    this.mapperToast(newToast);
    this.setNotificationToggle(newToast);
  }
}
