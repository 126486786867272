import { Book } from '@/globalInterfaces/Book';

interface StoreBooks {
  allBooks: Book[];
}

const Books = {
  state: {
    allBooks: []
  },
  mutations: {
    setAllBooks(state: StoreBooks, payload: Book[]) {
      state.allBooks = payload;
    }
  },
  getters: {
    allBooks: (state: StoreBooks) => state.allBooks
  }
};

export default Books;
