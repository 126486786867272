import { Component, Vue } from 'vue-property-decorator';

import CoursesService from '@/services/Courses/CoursesService';
import HistoricService from '@/services/Historic/historicService';

import { WatchedHistoryWeek } from '@/globalInterfaces/Course';

@Component({})
export default class ProgressBarCourse extends Vue {
  private listWatchedWeek: Array<WatchedHistoryWeek> = [];
  protected listOldAndCurrentWeek: Array<Record<string, any>> = [];

  protected CoursesService = new CoursesService();
  protected HistoricService = new HistoricService();

  async getWatchedWeek(idCourse: string) {
    try {
      const course = this.getCourseStore(Number(idCourse));

      const response = await this.HistoricService.getHistoricClassesWatched(
        idCourse,
        course?.languageId
      );

      if (response && response.length) {
        this.listWatchedWeek = response;
      }
    } catch (error) {
      console.error({ error });
    }
  }

  getCourseStore(idCourse: number) {
    const { courses } = this.$store.getters.profile?.preferences;

    if (courses && courses.length) {
      const foundCourse = courses.find(
        (course: Record<string, any>) => course?.courseId === idCourse
      );

      if (foundCourse) return foundCourse;
    }

    return {};
  }

  setProgressWeek(week: Record<string, any>) {
    if (this.listWatchedWeek && this.listWatchedWeek.length) {
      const weekWatched = this.listWatchedWeek.find(
        (weekItem: WatchedHistoryWeek) => weekItem.weekId === week.id
      );

      if (weekWatched) {
        const percent = Number(weekWatched?.watchedPercentage);

        return { width: `${percent}%`, backgroundColor: 'var(--primarycolor-pure)' };
      }

      return { width: `${100}%`, backgroundColor: '#e9ecef' };
    }

    return { width: `${100}%`, backgroundColor: '#e9ecef' };
  }

  setTitleProgressWeek(week: Record<string, any>) {
    const weekWatched = this.listWatchedWeek.find(
      (weekItem: WatchedHistoryWeek) => weekItem.weekId === week.id
    );

    if (weekWatched) {
      const percent = Number(weekWatched?.watchedPercentage);
      return `${percent}%`;
    }

    return '0%';
  }
}
