import { RouteConfig } from 'vue-router';

import { permissionAds } from '../Middleware/permission/Ads';

export const NAME_ROUTES_PLAN = {
  RENEW: 'RenewPlan',
  RENEW_PENDENCY: 'RenewPendencies',
  RENEW_PAYMENT_PIX: 'RenewPaymentPix',
  RENEW_PAYMENT_BILLET: 'RenewPaymentBillet',
  RENEW_PAYMENT_THANKS: 'RenewPaymentThankYou',
  UPDATE: 'FeedbackPermission'
};

export const Plan: Array<RouteConfig> = [
  {
    path: '/plano',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: 'renovacao',
        component: () => import('@/layouts/Default/index.vue'),
        children: [
          {
            path: '',
            name: NAME_ROUTES_PLAN.RENEW,
            component: () => import(/* webpackChunkName: "renew-plan" */ '@/pages/RenewPlan/RenewPlan.vue'),
            meta: { title: 'Renovação do plano' }
          },
          {
            path: ':idPurchase/pix',
            name: NAME_ROUTES_PLAN.RENEW_PAYMENT_PIX,
            component: () => import(
              /* webpackChunkName: "renew-payment-pix" */ '@/pages/RenewPlan/pages/PaymentPix/PaymentPix.vue'
            ),
            meta: { title: 'Pagamento com pix' }
          },
          {
            path: ':idPurchase/boleto',
            name: NAME_ROUTES_PLAN.RENEW_PAYMENT_BILLET,
            component: () => import(
              /* webpackChunkName: "renew-payment-billet" */ '@/pages/RenewPlan/pages/PaymentBillet/PaymentBillet.vue'
            ),
            meta: { title: 'Pagamento com boleto' }
          },
          {
            path: ':idPurchase/obrigado',
            name: NAME_ROUTES_PLAN.RENEW_PAYMENT_THANKS,
            component: () => import(
              /* webpackChunkName: "renew-payment-thanks" */ '@/pages/RenewPlan/pages/ThankYouPayment/index.vue'
            ),
            meta: { title: 'Obrigado pelo pagamento' }
          }
        ]
      },
      {
        path: 'pendencias',
        name: NAME_ROUTES_PLAN.RENEW_PENDENCY,
        component: () => import(
          /* webpackChunkName: "renew-pendency" */ '@/pages/RenewPlan/pages/PendencyDetail/PendencyDetail.vue'
        ),
        meta: { title: 'Pendências de renovação' }
      },
      {
        path: 'atualizar/:nameBreadcrumb',
        name: NAME_ROUTES_PLAN.UPDATE,
        component: () => import(
          /* webpackChunkName: "updated-plan" */ '@/pages/FeedbackPermission/FeedbackPermission.vue'
        ),
        meta: { title: 'Atualizar plano' },
        beforeEnter: permissionAds
      }
    ]
  }
];
