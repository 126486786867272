import { NavigationGuardNext, Route } from 'vue-router';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';

import Onboarding from '@/share/Util/Onboarding/Onboarding';

import Permission from '../permission/index';

const onboarding = new Onboarding();

export function onboardingRedaction(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (to.query && to.query.isFree) {
    next();
    return;
  }

  const PermissionClass = new Permission();

  if (
    onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.REDACTION)
    || !PermissionClass.can(LIST_PERMISSION.REDACTION_CREDITS)
  ) {
    next();
    return;
  }

  next({
    name: 'TutorialRedaction'
  });
}

export function onboardingCourse(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();
  const idCourses = to.params?.idCourses;

  const hasPermission = idCourses
    ? PermissionClass.can(IDS_FOR_PERMISSION_COURSE[Number(idCourses)])
    : Object.values(IDS_FOR_PERMISSION_COURSE).some((permission: string) => PermissionClass.can(permission));

  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.COURSE) || !hasPermission) {
    next();
    return;
  }

  next({
    name: 'TutorialCourse',
    params: {
      id: `${to.params.idCourses || ''}`
    }
  });
}

export function onboardingQuestions(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (to.query && to.query.isFree) {
    next();
    return;
  }

  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.EXERCISES)) {
    next();
    return;
  }

  next({
    name: 'TutorialQuestions'
  });
}

export function onboardingDoubts(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (to.query && to.query.isFree) {
    next();
    return;
  }

  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.DOUBTS)) {
    next();
    return;
  }

  next({
    name: 'TutorialDoubts'
  });
}

export function onboardingBooks(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (to.query && to.query.isFree) {
    next();
    return;
  }

  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.BOOKS)) {
    next();
    return;
  }

  next({
    name: 'TutorialBooks',
    params: {
      path: `${to.params?.path || ''}`
    }
  });
}

export function onboardingVideos(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.BOOKS_VIDEOS)) {
    next();
    return;
  }

  next({
    name: 'TutorialVideos',
    params: {
      context: `${to.params?.context || ''}`,
      path: `${to.params?.path || ''}`,
      topicID: `${to.params?.topicID || ''}`,
      fileID: `${to.params?.fileID || ''}`,
      slugFile: `${to.params?.slugFile || ''}`
    }
  });
}

export function onboardingExercise(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.BOOKS_EXERCISES)) {
    next();
    return;
  }

  next({
    name: 'TutorialExercise',
    params: {
      context: `${to.params?.context || ''}`,
      path: `${to.params?.path || ''}`,
      topicID: `${to.params?.topicID || ''}`,
      fileID: `${to.params?.fileID || ''}`,
      slugFile: `${to.params?.slugFile || ''}`
    }
  });
}

export function onboardingSimulated(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.SIMULATED)) {
    next();
    return;
  }

  next({
    name: 'TutorialSimulation'
  });
}

export function onboardingExam(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (to.query && to.query.isFree) {
    next();
    return;
  }

  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.EXAM)) {
    next();
    return;
  }

  next({
    name: 'TutorialExam'
  });
}

export function onboardingPlanner(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (to.query && to.query.isFree) {
    next();
    return;
  }

  if (onboarding.alreadyViewedOnboarding(LIST_ONBOARDING_ID.PLANNER)) {
    next();
    return;
  }

  next({
    name: 'TutorialPlanner'
  });
}
