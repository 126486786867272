import { Credit } from '@/globalInterfaces/CreditsConsumer';

interface StoreCreditConsumer {
  creditData: Credit;
}

const CreditsConsumer = {
  state: {
    creditData: null
  },
  mutations: {
    setCreditsConsumerData(state: StoreCreditConsumer, payload: Credit) {
      state.creditData = payload;
    },
    setCreditsConsumer(state: StoreCreditConsumer) {
      state.creditData = {
        ...state.creditData,
        consumedCredits: state.creditData.consumedCredits + 1
      };
    }
  },
  getters: {
    creditsConsumer: (state: StoreCreditConsumer): Credit => state.creditData
  }
};

export default CreditsConsumer;
