const INSTITUTION_NAME_DEFAULT = 'Explicaê';
const TAB_PROFILE_MY_PERFORMANCE = 1;
const TAB_NAME_PROFILE_NOTIFICATION = 'Notificações';
const LOGO_EXPLICAE_FREE = 'LogoExplicaeFree';
const LOGO_EXPLICAE = 'LogoExplicae';

const THREE_SECONDS_IN_MILLISECONDS = 3000;

const TAB_INDEX_NOTIFICATION = 4;

const LINK_SITE_EXPLICAE = 'https://lp.explicae.com.br/';

const ROUTE_STORE = 'Store';
const ROUTE_RENEW_PENDENCIES = 'RenewPendencies';
const ROUTE_PENDENCIES = 'Pendencies';

export {
  INSTITUTION_NAME_DEFAULT,
  TAB_PROFILE_MY_PERFORMANCE,
  TAB_NAME_PROFILE_NOTIFICATION,
  LOGO_EXPLICAE_FREE,
  LOGO_EXPLICAE,
  THREE_SECONDS_IN_MILLISECONDS,
  TAB_INDEX_NOTIFICATION,
  LINK_SITE_EXPLICAE,
  ROUTE_STORE,
  ROUTE_RENEW_PENDENCIES,
  ROUTE_PENDENCIES
};
