import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_HISTORIC = {
  PAYMENT: 'Historic'
};

export const Historic: Array<RouteConfig> = [
  {
    path: '/historico',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_HISTORIC.PAYMENT,
        component: () => import(
          /* webpackChunkName: "historicPayment" */ '@/pages/HistoricPayment/HistoricPayment.vue'
        ),
        meta: { title: 'Histórico de pagamentos' }
      }
    ]
  }
];
