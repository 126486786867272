





















































































































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import PlusPlanIcon from '@/assets/icons/PlusPlan.vue';
import CheckPlanIcon from '@/assets/icons/checkPlan.vue';

import { URLS_EXAM } from '@/constant/FreePlanUrls';

@Component({
  components: {
    Modal,
    PlusPlanIcon,
    CheckPlanIcon
  }
})
export default class ModalPopUpPlanFreeExam extends Vue {
  private URLS_EXAM = URLS_EXAM;
}
