import { Degree } from '@/globalInterfaces/Book';

interface StoreDegrees {
  degrees: Degree[];
}

const Degrees = {
  state: {
    degrees: []
  },
  mutations: {
    setDegrees(state: StoreDegrees, payload: Degree[]) {
      state.degrees = payload;
    }
  },
  getters: {
    degrees: (state: StoreDegrees) => state.degrees
  }
};

export default Degrees;
