import { NavigationGuardNext, Route } from 'vue-router';

import store from '@/store';

import Permission from './index';

import { PERMISSION_NAME_BY_DEGREE_ID } from '@/constant/PermissionBookByDegreeID';
import { LIST_PERMISSION } from '@/constant/ListPermission';

import BookService from '@/services/Book/BookService';

import { Book, Degree } from '@/globalInterfaces/Book';

const bookService = new BookService();
const PermissionClass = new Permission();

const CONTEXT_BOOK = 'caderno';
const NOT_FOUND = -1;

function getDegreeIDsWithPermission(degrees: Degree[]) {
  const degreesWithPermission = degrees
    .filter((degree: Degree) => PermissionClass.can(PERMISSION_NAME_BY_DEGREE_ID[degree.ID]))
    .map((degree: Degree) => degree.ID);

  return degreesWithPermission;
}

async function getDegreeIDs() {
  let degreesWithPermission: number[] = [];

  try {
    const degrees = await bookService.getDegrees();

    degreesWithPermission = getDegreeIDsWithPermission(degrees);
  } catch (error) {
    console.error(error);
  }

  return degreesWithPermission;
}

async function findPermissionDegreeID() {
  const degreesWithPermission = await getDegreeIDs();

  return degreesWithPermission;
}

async function verifyBookPermission(permissionsDegreeIDs: number[], to: Route) {
  let bookFound = NOT_FOUND;

  try {
    const { path } = to.params;

    const allDegreeBooksWithPermission: Book[] = await bookService.books(
      permissionsDegreeIDs,
      store.getters.profile?.institution?.id
    );

    bookFound = allDegreeBooksWithPermission && allDegreeBooksWithPermission.length
      ? allDegreeBooksWithPermission.findIndex((book: Book) => book.slug === path)
      : NOT_FOUND;
  } catch (error) {
    console.error(error);
  }

  return bookFound !== NOT_FOUND;
}

export async function permissionBookFeature(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) {
  const hasPermissionInHighSchool = PermissionClass.can(LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL);

  const { context } = to.params;

  if (context && context !== CONTEXT_BOOK && hasPermissionInHighSchool) {
    next();
    return;
  }

  const listPermissionsDegreeID = await findPermissionDegreeID();

  const hasPermissionBook = await verifyBookPermission(listPermissionsDegreeID, to);

  if (hasPermissionBook) {
    next();
    return;
  }

  next({
    name: 'Dashboard'
  });
}
