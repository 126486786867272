import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionPlanner } from '../Middleware/permission/Planner';
import { permissionFeature } from '../Middleware/permission/Feature';

import { onboardingPlanner } from '../Middleware/onboarding';

export const NAME_ROUTES_PLANNER = {
  PLANNER: 'Planner',
  TUTORIAL: 'TutorialPlanner',
  CONFIG: 'PlannerConfig'
};

export const Planner: Array<RouteConfig> = [
  {
    path: '/planner',
    component: () => import('@/layouts/Sidebar/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_PLANNER.PLANNER,
        component: () => import(/* webpackChunkName: "planner" */ '@/pages/Planner/Planner.vue'),
        meta: { title: 'Plano de estudos' },
        beforeEnter: multiGuard([permissionFeature, onboardingPlanner])
      },
      {
        path: 'configuracao',
        name: NAME_ROUTES_PLANNER.CONFIG,
        component: () => import(
          /* webpackChunkName: "planner-config" */ '@/pages/Planner/pages/PlannerConfig/PlannerConfig.vue'
        ),
        meta: { title: 'Configuração do Plano de estudos' },
        beforeEnter: multiGuard([permissionFeature, permissionPlanner])
      }
    ]
  },
  {
    path: '/planner',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: 'tutorial',
        name: NAME_ROUTES_PLANNER.TUTORIAL,
        component: () => import(
          /* webpackChunkName: "planner-tutorial" */ '@/pages/Planner/pages/TutorialPlanner/index.vue'
        ),
        meta: { title: 'Tutorial Planner' },
        beforeEnter: permissionFeature
      }
    ]
  }
];
