function CurrencyNumberFormat() {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  return formatter;
}

export { CurrencyNumberFormat };
