


















































import { Component, Vue, Prop } from 'vue-property-decorator';

import { InstanceAmplitude } from '@/share/Util//Metrics/Metrics';

import { ProductCard } from '@/globalInterfaces/ProductCard';

import Cart from '@/assets/icons/Cart.vue';

@Component({
  components: {
    Cart
  },
  filters: {
    formatCoin(value: number) {
      return value.toFixed(2) || '';
    }
  }
})
export default class CardProduct extends Vue {
  @Prop({ required: true }) cardProductData!: ProductCard;
  @Prop() textButtonBuy!: string;

  get categoryIsCourse() {
    const CATEGORY_COURSE = 'CURSO';
    return this.cardProductData.category === CATEGORY_COURSE;
  }

  handleClickBuyProduct() {
    this.$emit('click-buy-product', this.cardProductData);
    const CLICK_LOCATION_BUY_BUTTON = 'Comprar';
    this.setTrackAmplitude(CLICK_LOCATION_BUY_BUTTON);
  }

  setTrackAmplitude(clickLocation: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'CardProduct',
          local: clickLocation,
          category: this.cardProductData.category,
          productName: this.cardProductData.title
        }
      }
    });
  }
}
