const Plan = {
  state: {
    title: 'Load',
    totalRedactionsMeMonthly: null
  },
  mutations: {
    setTotalRedactionsMeMonthly(state: Record<string, any>, payload: number) {
      state.totalRedactionsMeMonthly = payload;
    },
    setTitlePlan(state: Record<string, any>, payload: string) {
      state.title = payload;
    }
  },
  getters: {
    plan: (state: Record<string, any>) => state.title,
    totalRedactionsMeMonthly: (state: Record<string, any>) => state.totalRedactionsMeMonthly
  }
};

export default Plan;
