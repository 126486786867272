import { NavigationGuardNext, Route } from 'vue-router';
import isPlanCoursePermission from '@/share/Util/Permissions/planCourse';

const NAME_COURSE_CONTEXT = 'cursos';

function permissionPlanCoursePage(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  if (isPlanCoursePermission()) {
    next({
      name: 'begin'
    });
  }

  next();
}

function permissionPlanCoursePageForContext(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
) {
  const context = to?.params?.context;

  if (!isPlanCoursePermission() || (isPlanCoursePermission() && context === NAME_COURSE_CONTEXT)) next();
  else {
    next({
      name: 'begin'
    });
  }
}

export { permissionPlanCoursePage, permissionPlanCoursePageForContext };
