const Book = {
  state: {
    book: {},
    bookTopic: [],
    topicsBySlugBook: {},
    bookPath: '',
    topicsBook: [],
    teachersBook: []
  },
  mutations: {
    setBook(state: Record<string, any>, payload: Record<string, string>) {
      state.book = payload;
    },
    setBookTopic(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.bookTopic = payload;
    },
    setBookPath(state: Record<string, any>, payload: string) {
      state.bookPath = payload;
    },
    setClearBookPath(state: Record<string, any>) {
      state.bookPath = '';
    },
    setTopicsBook(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.topicsBook = [...state.topicsBook, payload];
    },
    setClearTopicsBook(state: Record<string, any>) {
      state.topicsBook = [];
    },
    setTeachersBook(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.teachersBook = payload;
    },
    setTopicsBySlugBook(state: Record<string, any>, payload: Record<string, any>) {
      state.topicsBySlugBook = payload;
    }
  },
  actions: {
    clearBookTopicAndPath(context: Record<string, any>) {
      context.commit('setClearBookPath');
      context.commit('setClearTopicsBook');
    }
  },
  getters: {
    book: (state: Record<string, any>) => state.book,
    bookTopic: (state: Record<string, any>) => state.bookTopic,
    bookPath: (state: Record<string, any>) => state.bookPath,
    topicsBook: (state: Record<string, any>) => state.topicsBook,
    topicsBookAndPath: (state: Record<string, any>) => ({
      bookSlug: state.bookPath,
      topics: state.topicsBook
    }),
    teachersBook: (state: Record<string, any>) => state.teachersBook,
    topicsBySlugBook: (state: Record<string, any>) => state.topicsBySlugBook
  }
};

export default Book;
