
























import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

import BoxWithArrow from '@/components/BoxContainer/BoxWithArrow/index.vue';

@Component({
  components: {
    BoxWithArrow
  }
})
export default class SubjectBox extends Vue {
  @Prop() index!: number;
  @Prop() showIndex!: boolean;
  @Prop({ default: true }) showArrow!: boolean;
  @Prop() title!: string;
  private openSubject = this.showIndex;

  setOpenSubject() {
    this.openSubject = this.showArrow ? !this.openSubject : false;
  }

  @Watch('$route.params.path')
  setClosed() {
    this.openSubject = false;
  }
}
