




























import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

const URL_CONTEXT_BOOK = 'https://pages.explicae.com.br/compare-os-planos?utm_source=plataformafree&utm_medium=creditocaderno';
const URL_CONTEXT_QUESTION = 'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=creditoquestoes';
const NAME_PATH_QUESTION = 'AnswerQuestion';

@Component({
  components: {
    Modal
  }
})
export default class ModalCredits extends Vue {
  get URL() {
    return this.$route.name === NAME_PATH_QUESTION ? URL_CONTEXT_QUESTION : URL_CONTEXT_BOOK;
  }

  handleClickButton() {
    this.setTrackAmplitude();

    window.open(this.URL, '_blank');
  }

  setTrackAmplitude() {
    const { path } = this.$route;

    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Modal Credits Free',
          local: 'Quero assinar',
          path
        }
      }
    });
  }
}
