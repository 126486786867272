

















import Slick from 'vue-slick';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: { Slick }
})
export default class ExSlick extends Vue {
  @Prop() options!: object;
  @Prop() length!: number;
  @Prop({ default: 3 }) maxCards!: number;

  private slickOptions = {
    slidesToShow: 3,
    variableWidth: true,
    infinite: false,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1
        }
      }
    ]
  };
  private lengthCards = 1;

  get slickOptionsValue() {
    return this.options || this.slickOptions;
  }

  created() {
    this.slickOptions.slidesToShow = this.maxCards;
  }

  next() {
    (this as any).$refs.slick.next();
  }

  prev() {
    (this as any).$refs.slick.prev();
  }

  reInit() {
    this.$nextTick(() => {
      (this as any).$refs.slick.reSlick();
    });
  }
}
