import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionBookFeature } from '../Middleware/permission/Book';
import { permissionCourse } from '../Middleware/permission/Course';
import { permissionPlannerContext } from '../Middleware/permission/Planner';
import { permissionVideo } from '../Middleware/permission/Videos';

import { onboardingVideos } from '../Middleware/onboarding';

import { redirectVideos } from '../Middleware/redirect/videos';

export const NAME_ROUTES_VIDEO = {
  VIDEO: 'videos',
  TUTORIAL: 'TutorialVideos',
  REDIRECT: 'VideoRedirect'
};

export const Video: Array<RouteConfig> = [
  {
    path: '/videos',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: 'redirect',
        name: NAME_ROUTES_VIDEO.REDIRECT,
        beforeEnter: redirectVideos
      },
      {
        path: ':context/:path/:topicID/:fileID/:slugFile/tutorial',
        name: NAME_ROUTES_VIDEO.TUTORIAL,
        component: () => import(
          /* webpackChunkName: "video-tutorial" */ '@/pages/Video/pages/TutorialVideos/index.vue'
        ),
        meta: { title: 'Tutorial Vídeos' }
      }
    ]
  },
  {
    path: '/videos/:context/:path/:topicID/:fileID/:slugFile',
    component: () => import('@/layouts/Sidebar/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_VIDEO.VIDEO,
        component: () => import(/* webpackChunkName: "video" */ '@/pages/Video/Video.vue'),
        meta: { title: 'Vídeos' },
        beforeEnter: multiGuard([
          permissionBookFeature,
          permissionCourse,
          permissionPlannerContext,
          onboardingVideos,
          permissionVideo
        ])
      }
    ]
  }
];
