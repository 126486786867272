const COURSE_SALE = [
  {
    ids: [33],
    image: 'card/cover-courses/enem-60-dias.png',
    categoryColor: '#568414',
    title: '60 dias para o ENEM',
    category: 'CURSO',
    description:
      'Dois meses de conteúdo completo, para você revisar os assuntos mais importantes para a prova do ENEM.',
    installment: '3',
    value: '34.90',
    link: 'https://carrinho.explicae.com.br/comprar/461/quarter/',
    knowMore: 'https://pages.explicae.com.br/curso-60-dias-para-o-enem'
  },
  {
    ids: [32],
    image: 'card/cover-courses/revisao-meio-do-ano.jpg',
    categoryColor: '#568414',
    title: 'Revisão de Meio do Ano',
    category: 'CURSO',
    description: 'Ideal para quem quer revisar os assuntos mais importantes do 1º semestre.',
    installment: '3',
    value: '39.90',
    link: 'https://carrinho.explicae.com.br/comprar/372/quarter',
    knowMore: 'https://pages.explicae.com.br/revisao-de-meio-do-ano'
  },
  {
    ids: [34],
    image: 'card/cover-courses/revisao-final-enem.png',
    categoryColor: '#568414',
    title: 'Revisão Final ENEM',
    category: 'CURSO',
    description:
      'Dois meses de conteúdo completo, para você revisar os assuntos mais importantes para a prova do ENEM.',
    installment: '3',
    value: '32.00',
    link: 'https://carrinho.explicae.com.br/comprar/479/quarter',
    knowMore: 'https://pages.explicae.com.br/curso-revisao-final-enem'
  }
];

const PRODUCTS_SALE = [
  {
    ids: 'manual-enem',
    image: 'card/cover-products/manual-enem.png',
    categoryColor: '#3B82F6      ',
    title: '(Pré-venda) Manual de Sobrevivência do ENEM',
    category: 'PRODUTO',
    description: 'Para você que quer mandar bem no ENEM, esse é o manual perfeito para isso.',
    installment: '12',
    value: '57.81',
    knowMore:
      'https://pages.explicae.com.br/produtos/manual-sobrevivencia-enem?utm_source=plataforma',
    link: 'https://manual.explicae.com.br/?add-to-cart=15712?utm_source=plataforma'
  },
  {
    ids: 'see',
    image: 'card/cover-products/see.png',
    categoryColor: '#3B82F6      ',
    title: 'SEE - Sistema de Ensino Explicaê',
    category: 'PRODUTO',
    description: 'Esse material é perfeito para você que quer mandar bem no ENEM e vestibulares.',
    installment: '12',
    value: '135.42',
    knowMore: 'https://pages.explicae.com.br/produtos/sistema-de-ensino-explicae',
    link: 'https://manual.explicae.com.br/carrinho/?add-to-cart=13316'
  },
  {
    ids: 'manual-sobrevivencia',
    image: 'card/cover-products/manual-fuvest.png',
    categoryColor: '#3B82F6      ',
    title: 'Manual de Sobrevivência da FUVEST',
    category: 'PRODUTO',
    description: 'O manual completo para você mandar bem na FUVEST.',
    installment: '7',
    value: '48.00',
    knowMore: 'https://pages.explicae.com.br/produtos/manual-sobrevivencia-fuvest',
    link: 'https://manual.explicae.com.br/carrinho/?add-to-cart=11976'
  },
  {
    ids: 'planner',
    image: 'card/cover-products/planner.png',
    categoryColor: '#3B82F6      ',
    title: 'Planner Clube da Aprovação',
    category: 'PRODUTO',
    description:
      'Com o planner clube da aprovação, você vai aprender a acompanhar seus estudos da melhor maneira.',
    installment: '4',
    value: '53.75',
    knowMore: 'https://pages.explicae.com.br/produtos/planner-clube-da-aprovacao',
    link: 'https://manual.explicae.com.br/carrinho/?add-to-cart=13825'
  }
];

export const LIST_PRODUCTS_FOR_SALE = [
  // {
  //   id: 'courses',
  //   products: COURSE_SALE,
  // },
  {
    id: 'products',
    products: PRODUCTS_SALE
  }
];
