import { NavigationGuardNext, Route } from 'vue-router';

import BookService from '@/services/Book/BookService';

import { Book } from '@/globalInterfaces/Book';

const bookService = new BookService();

async function getBooks() {
  const books: Book[] = await bookService.books();

  return books || [];
}

export async function redirectVideos(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const { redirect } = to.query;

  if (!redirect) {
    next({
      name: 'Dashboard'
    });
  }

  const response = await bookService.getVideosRedirect(String(redirect));

  if (!response) {
    next({
      name: 'Dashboard'
    });
  }

  const {
    slugDiscipline, topicID, videoID, slugVideo 
  } = response;

  const books = await getBooks();

  const foundBook = books.find((book) => book.slug === slugDiscipline);

  if (!foundBook) {
    next({
      name: 'Dashboard',
      query: {
        videoID
      }
    });

    return;
  }

  next({
    name: 'videos',
    params: {
      context: 'caderno',
      path: `${slugDiscipline}`,
      topicID: `${topicID}`,
      fileID: `${videoID}`,
      slugFile: `${slugVideo}`
    }
  });
}
