export const LIST_TYPE_BOOKS = {
  BOOK: 'books',
  COURSE: 'courses',
  SIMULATION: 'simulation',
  GUIA_VESTIBULANDO_ENEM: 'Guia_ENEM',
  GUIA_VESTIBULANDO_VESTIBULARES: 'Guia_VESTIBULARES',
  EXAM_REGIONAL: 'Regional',
  EXAM_NATIONAL_ENEM: 'National_ENEM',
  EXAM_NATIONAL_MILITARES: 'National_MILITARES'
};
