import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_PROFILE = {
  PROFILE: 'Profile'
};

export const Profile: Array<RouteConfig> = [
  {
    path: '/profile',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_PROFILE.PROFILE,
        component: () => import(/* webpackChunkName: "profile" */ '@/pages/Profile/Profile.vue'),
        meta: { title: 'Profile' }
      }
    ]
  }
];
