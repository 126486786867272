





























































import { Component, Prop, Vue } from 'vue-property-decorator';

import Close from '@/assets/icons/Close.vue';
import Loading from './Loading.vue';

/**
 * Componente do Bootstrap
 * A prop *classesModalBootstrap* pode receber as classes do boostrap para modal
 */
@Component({
  components: {
    Close,
    Loading
  }
})
export default class Modal extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ default: false }) isShowHeader!: boolean;
  @Prop({ default: false }) isShowFooter!: boolean;
  @Prop({ default: 'modal-dialog-centered' }) classesModalBootstrap!: string;
  @Prop({ default: true }) backdrop!: string | boolean;
  @Prop({ default: true }) isKeyboard!: boolean;
  @Prop({ default: false }) isBtnClose!: boolean;
  @Prop({ default: true }) showTemplateDefault!: boolean;
  @Prop({ default: false }) isLoading!: boolean;

  closeModal() {
    this.$emit('close-modal');
  }
}
