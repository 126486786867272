

















































import {
  Component,
  Mixins
} from 'vue-property-decorator';

import Books from '@/components/Book/index.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ExSlick from '@/components/Slick/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';

import CoursesService from '@/services/Courses/CoursesService';

import { Course, NewCourse } from '@/globalInterfaces/Course';

import AccessCourse from '@/mixins/AccessCourses';
import generalBooks from '@/mixins/generalBooks';

@Component({
  components: {
    Books,
    BoxContainer,
    ExSlick,
    ExLoading,
    FeedbackUser
  }
})
export default class MyCourses extends Mixins(generalBooks, AccessCourse) {
  private listCourses: Array<NewCourse> = [];
  private isLoadingCourses = false

  private CoursesService = new CoursesService();

  created() {
    this.getAndSetCoursesWithAccess();
  }

  async getAndSetCoursesWithAccess() {
    try {
      this.isLoadingCourses = true;

      const allCourses: Array<Course> = await this.CoursesService.getCourses();

      if (allCourses.length) this.setCourses(allCourses);

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os cursos.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingCourses = false;
    }
  }

  setCourses(courses: Array<Course>) {
    const listCourses = courses.filter((course: Course) => !this.isDisableCourse(course.idCourses)).map((course: Course) => {
      const newCourseData = {
        id: course.idCourses,
        colorBook: course.color,
        cover: course.thumb,
        slug: course.slug,
        link: '',
        type: 'courses',
        title: course.title,
        visible: true,
        redirect: this.verifyCourse,
        disable: false,
        firstAccess: this.checkCourseAccess(course.idCourses)
      };

      return { ...course, ...newCourseData };
    });

    this.listCourses = listCourses.sort((courseA: Record<string, any>, courseB: Record<string, any>) => courseA.disable - courseB.disable);

    this.emitCourseWithAccess();
  }

  emitCourseWithAccess() {
    this.$emit('courses-access', this.listCourses);
  }

  verifyCourse(id: number, slug: string, title: string) {
    const courseSelected = this.listCourses.find((course: NewCourse) => course.idCourses === id);

    if (courseSelected) this.verifyFirstAccessCourse(courseSelected);
  }

}
