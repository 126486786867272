var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"opacity-75 content-loader",style:({
    width: _vm.computedWidth,
    height: _vm.height,
    maxWidth: _vm.maxWidth,
    backgroundColor: _vm.isDarkMode ? _vm.BACKGROUND_DARK : _vm.BACKGROUND_LIGHT,
  })},[_c('span',{staticClass:"content-loader__fx",style:({
      animationDuration: _vm.animationDuration,
      backgroundImage: ("linear-gradient(to right, " + _vm.colorStopOne + " 8%, " + _vm.colorStopTwo + " 18%, " + _vm.colorStopOne + " 36%)"),
    })}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }