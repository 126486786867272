import { Vue, Component } from 'vue-property-decorator';

import getPermissionRedaction from '@/share/Util/Permissions/redaction';
import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component
export default class OpenInstructionRedaction extends Vue {
  goToInstruction(themeId: number, componentName = 'ThemeWeek') {
    const hasCredit = this.$store.getters.canOpenProposals;

    const hasPermissionRedaction = getPermissionRedaction();

    this.setTrackAmplitude(componentName, themeId);

    if (hasPermissionRedaction) {
      if (hasCredit) {
        this.$router.push({
          name: 'RedactionInstruction',
          params: {
            themeId: String(themeId)
          }
        });
      } else {
        this.$emit('showModal', 'ModalCreditIsOver');
      }
    } else {
      this.$router.push({
        name: 'FeedbackPermission',
        params: {
          nameBreadcrumb: 'Redação'
        }
      });
    }
  }

  setTrackAmplitude(componentName: string, redactionID: number) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: componentName,
          local: this.$route.name,
          redactionID
        }
      }
    });
  }
}
