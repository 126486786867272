import { NavigationGuard, NavigationGuardNext, RawLocation } from 'vue-router';

// link de referência: https://github.com/vuejs/vue-router/issues/2688
export type MultiGuard = (guards: NavigationGuard[]) => NavigationGuard;

export const multiGuard: MultiGuard = (guards: NavigationGuard[]): NavigationGuard => async (
  to,
  from,
  next
) => {
  let resolved = false;

  const sharedNext = (location?: RawLocation): void => {
    if (location) {
      resolved = true;
      next(location);
    }
  };

  // eslint-disable-next-line no-restricted-syntax
  for await (const guard of guards) {
    await guard(to, from, sharedNext as NavigationGuardNext);
    if (resolved) break;
  }

  if (!resolved) next();
};
