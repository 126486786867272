
















import { Component, Vue } from 'vue-property-decorator';

import ListVideos from '@/widgets/Video/ListVideos/ListVideos.vue';
import LastListVideos from '@/widgets/Video/LastListVideos/index.vue';
// import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_MONTHS } from '@/constant/Dates';

const TEXT_DEFAULT = 'Últimas aulas assistidas';

@Component({
  components: {
    ListVideos,
    LastListVideos
  }
})
export default class ContainerVideos extends Vue {
  get plan() {
    return this.$store.getters.profile?.plan;
  }

  // get isPlanFree() {
  //   return this.plan?.planID === LIST_PLAN_ID.FREE;
  // }

  get getTitle() {
    // return this.isPlanFree ? `${TEXT_DEFAULT} - Visitadas em ${this.getFormattedDate()}` : TEXT_DEFAULT;

    return TEXT_DEFAULT;
  }

  getFormattedDate() {
    const currentDate = new Date();

    const numberMonth = currentDate.getMonth();

    const year = currentDate.getFullYear();

    const nameMonth = LIST_MONTHS.find((month, index) => index === numberMonth)?.title;

    return `${nameMonth}/${year}`;
  }
}
