import cookies from 'browser-cookies';
import merge from 'lodash/merge';

const DEFAULT_OPTIONS = {
  domain: '.explicae.com.br',
  httponly: true
};

merge(cookies.defaults, DEFAULT_OPTIONS);

function getCookie(key: string) {
  return cookies.get(key);
}

function eraseCookie(key: string) {
  cookies.erase(key, DEFAULT_OPTIONS);
}

function setCookie(name: string, value: string, options?: any) {
  cookies.set(name, String(value), merge({}, DEFAULT_OPTIONS, options));
}

function getAllCookies() {
  return cookies.all();
}

function removeAccess() {
  eraseCookie('auth_token');
  eraseCookie('access_id');
  eraseCookie('access_token');
  localStorage.removeItem('token');
}
export default {
  getCookie,
  eraseCookie,
  setCookie,
  getAllCookies,
  removeAccess
};
