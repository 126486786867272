import { RouteConfig } from 'vue-router';

import DashboardPage from '@/pages/Dashboard/Dashboard.vue';

export const NAME_ROUTES_DASHBOARD = {
  DASHBOARD: 'Dashboard'
};

export const Dashboard: Array<RouteConfig> = [
  {
    path: '/dashboard',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_DASHBOARD.DASHBOARD,
        component: DashboardPage,
        meta: { title: 'Dashboard' }
      }
    ]
  }
];
