





















import { Component, Vue, Prop } from 'vue-property-decorator';

import html2canvas from 'html2canvas';

import Modal from '@/globalComponents/Modal/Modal.vue';
import { RankingMonthLocal } from '@/globalInterfaces/Gamification';

import UserPodium from '../UserPodium/index.vue';

import ListButtonShare from '@/components/Share/ListButtonsShare/ListButtonShare.vue';

@Component({
  components: {
    Modal,
    UserPodium,
    ListButtonShare
  }
})
export default class ModalSharePouserDatadium extends Vue {
  @Prop({ required: true }) userData!: RankingMonthLocal;
  @Prop({ required: true }) month!: string;

  async captureImage() {
    const element: any = this.$refs.userpodium;

    if (!element) return;

    try {
      const userPodiumElement = element.querySelector('.user-podium');

      const originalBoxShadow = userPodiumElement.style.boxShadow;

      userPodiumElement.style.boxShadow = 'none';

      const canvas = await html2canvas(element, {
        allowTaint: false,
        useCORS: true
      });

      userPodiumElement.style.boxShadow = originalBoxShadow;

      const base64Image = canvas.toDataURL('image/png');

      const link = document.createElement('a');

      link.href = base64Image;

      link.download = `aluno-destaque-${this.month}-explicae.png`;

      link.click();
    } catch (error) {
      console.error(error);

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao baixar a imagem.',
        status: 'error'
      });
    }
  }
}
