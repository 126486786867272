import { ConfigWar } from '@/globalInterfaces/War';
import { CountryResponse, ResponseCardUser, ResponseCountryUser } from '@/services/War/WarInterface';

const War = {
  namespaced: true,
  state: {
    configWar: null as ConfigWar | null,
    isWarRoute: false,
    countries: [] as unknown as CountryResponse[],
    countriesUser: [] as ResponseCountryUser[],
    cardUser: {} as ResponseCardUser
  },
  mutations: {
    setConfig(state: Record<string, any>, payload: ConfigWar) {
      state.configWar = payload;
    },
    setIsWarRoute(state: any, payload: boolean) {
      state.isWarRoute = payload;
    },
    setCountries(state: any, countries: CountryResponse[]) {
      state.countries = countries;
    },
    setCountriesUser(state: any, countries: ResponseCountryUser[]) {
      state.countriesUser = countries;
    },
    setCardUser(state: any, cardUser: ResponseCardUser) {
      state.cardUser = cardUser;
    }
  },
  getters: {
    configWar: (state: Record<string, any>) => state.configWar,
    isWarRoute: (state: Record<string, any>) => state.isWarRoute,
    countries: (state: Record<string, any>) => state.countries,
    countriesUser: (state: Record<string, any>) => state.countriesUser,
    cardUser: (state: Record<string, any>) => state.cardUser,
    conqueredCountriesCount: (state: Record<string, any>) => (state.cardUser.country ? state.countriesUser.length + 1 : state.countriesUser.length)
  }
};

export default War;
