
















































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

// import CoursesFeedbackRestriction from '@/components/FeedbackRestrictionBooks/CoursesFeedbackRestriction/CoursesFeedbackRestriction.vue';
// import SimulatedFeedbackRestriction from '@/components/FeedbackRestrictionBooks/SimulatedFeedbackRestriction/SimulatedFeedbackRestriction.vue';
// import QuestionsFeedbackRestriction from '@/components/FeedbackRestrictionBooks/QuestionsFeedbackRestriction/QuestionsFeedbackRestriction.vue';

import { LIST_TYPE_BOOKS } from '@/constant/ListTypesBooks';

import { InstanceAmplitude } from '@/share/Util//Metrics/Metrics';

// const NAME_COMPONENT_FEEDBACK_COURSES = 'CoursesFeedbackRestriction';
// const NAME_COMPONENT_FEEDBACK_SIMULATED = 'SimulatedFeedbackRestriction';
// const NAME_COMPONENT_FEEDBACK_QUESTIONS = 'QuestionsFeedbackRestriction';

// const LIST_TYPE_BOOK_QUESTIONS = [
//   LIST_TYPE_BOOKS.GUIA_VESTIBULANDO_ENEM,
//   LIST_TYPE_BOOKS.GUIA_VESTIBULANDO_VESTIBULARES,
//   LIST_TYPE_BOOKS.EXAM_REGIONAL,
//   LIST_TYPE_BOOKS.EXAM_NATIONAL_ENEM,
//   LIST_TYPE_BOOKS.EXAM_NATIONAL_MILITARES,
// ];

const DEFAULT_COLOR = '#F1EFEF';

@Component({
  components: {
    // CoursesFeedbackRestriction,
    // SimulatedFeedbackRestriction,
    // QuestionsFeedbackRestriction,
  }
})
export default class Book extends Vue {
  @Prop() private fromDashboard!: boolean;
  @Prop() private cover!: string;
  @Prop() private link!: string;
  @Prop() private slug!: string;
  @Prop() private newWidth!: string;
  @Prop() private colorBook!: string;
  @Prop() private booksClass!: string;
  @Prop() private disable!: string;
  @Prop() private disableClick!: string;
  @Prop() private tag!: string;
  @Prop() private linkRedirectFeedback!: string;
  @Prop() private id!: number;
  @Prop() private redirect!: Function;
  @Prop() private list!: Array<Record<string, any>>;
  @Prop() private status!: number;
  @Prop({ default: true }) private showFeedbackComponent!: boolean;
  @Prop({ default: 'books' }) private type!: string;
  @Prop({ default: 'Caderno' }) private title!: string;

  private styleBook = '';
  private showFeedbackRestriction = false;
  private disableSimulatedBook = false;

  private TYPE_SIMULATION = LIST_TYPE_BOOKS.SIMULATION;

  mounted() {
    this.setResize();
  }

  get defaultColorBook() {
    return this.colorBook || DEFAULT_COLOR;
  }

  // get typeComponent() {
  //   if (this.showFeedbackComponent) {
  //     if (this.type === LIST_TYPE_BOOKS.COURSE) return NAME_COMPONENT_FEEDBACK_COURSES;
  //     if (this.type === LIST_TYPE_BOOKS.SIMULATION) return NAME_COMPONENT_FEEDBACK_SIMULATED;
  //     if (LIST_TYPE_BOOK_QUESTIONS.includes(this.type)) return NAME_COMPONENT_FEEDBACK_QUESTIONS;
  //     return '';
  //   }

  //   return '';
  // }

  get getClasses() {
    let classes = this.booksClass || '';
    if (this.disable || this.disableClick || this.disableSimulatedBook) {
      classes += '--disable-action';
    }
    return classes;
  }

  get getBookStyle() {
    return this.styleBook;
  }

  setResize() {
    const time = setInterval(() => {
      if (this.$el && this.$el.clientWidth > 80) {
        clearInterval(time);
        this.resize();
      }
    }, 10);
  }

  // @Watch('$responsive.isMobile')
  // isMobile() {
  //   this.showFeedbackRestriction = window.screen.width <= 820;
  // }

  async openLink(link: string) {
    if (!this.disable) {
      this.setTrackAmplitude();

      if (this.redirect) {
        await this.redirect(this.id, this.slug, this.title);
        return;
      }

      if (link === this.$route.fullPath) {
        return;
      }

      if (link !== '') {
        this.$router.push(link);
      }
    }
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Book',
          type: this.type,
          slug: this.slug
        }
      }
    });
  }

  @Watch('newWidth')
  @Watch('$responsive.contentwidth')
  resize() {
    const bookWidth = this.$el.clientWidth;
    const percentWidth = (100 * bookWidth) / 281;
    const newBookheight = Math.ceil((percentWidth * 440) / 100);

    this.styleBook = `
      height: ${this.convertPxToRem(newBookheight)}rem;
      width: ${this.convertPxToRem(bookWidth)}rem;
      color: #000;
    `;
  }

  convertPxToRem(value: number) {
    const DEFAULT_VALUE_PX = 16;
    return value / DEFAULT_VALUE_PX;
  }
}
