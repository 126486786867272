import store from '@/store';

import AppClient from '../AppClient';

import { searchStoreFiles } from '../Util/searchStoreFiles';

export default class ExerciseService {
  private basePath = process.env.VUE_APP_BOOK_API;

  private Client = new AppClient({ url: this.basePath });

  async getExercise(idExercise: number) {
    const URI = '/exercises';

    const response = await this.Client.get(URI, {
      idExercise: [idExercise]
    });

    store.commit('setExercise', response?.data[0]);

    return response?.data[0];
  }

  async getExercises(listExerciseIds: Array<number>) {
    const filterExercises = searchStoreFiles(listExerciseIds, 'filesExercices', 'idExercice');

    if (filterExercises.idsFiles && filterExercises.idsFiles.length) {
      const URI = '/exercises';

      const response = await this.Client.get(URI, {
        idExercise: filterExercises.idsFiles
      });

      if (response?.data) {
        const files = [...filterExercises.files, ...response?.data];

        store.commit('setFilesExercices', files);

        return files;
      }

      return response?.data;
    }

    return filterExercises.files;
  }

  async getQuestionsByExercise(exercises: Array<number> = []) {
    const URI = '/exercises/questions/count';

    const response = await this.Client.get(URI, {
      idExercise: exercises
    });

    return response?.data;
  }

  async getExerciseByUser(idExercise: number) {
    const URI = `/exercises/${idExercise}/answer`;

    const response = await this.Client.get(URI);

    store.commit('setAnsweredExercises', response?.data);

    return response?.data;
  }

  async saveExercise(
    idExercise: number,
    questionsAnswered: Record<string, any>,
    discipline: number,
    totalTime: number
  ) {
    let start = new Date();
    start = new Date(start.getTime() - totalTime * 100);

    const end = new Date();
    const answer: Record<string, any> = [];

    questionsAnswered.forEach((question: any) => {
      question.options.forEach((option: any) => {
        if (option.value && option.correct === 1) {
          answer.push({
            questionID: question.id,
            validated: 1,
            optionID: option.idOptions,
            registry_date: question.registry_date
          });
        } else if (option.value && option.correct === 0) {
          answer.push({
            questionID: question.id,
            validated: 0,
            optionID: option.idOptions,
            registry_date: question.registry_date
          });
        }
      });
    });

    const data = {
      answer,
      disciplinesId: discipline,
      duration: 0,
      end: end.getTime(),
      start: start.getTime(),
      total_questions: questionsAnswered.length
    };

    const URI = `/exercises/${idExercise}/answer`;

    const response = await this.Client.post(URI, data);

    return response;
  }

  async getExerciseGraphics(idExercise: number) {
    const URI = `/exercises/${idExercise}/users`;

    const response = await this.Client.get(URI);

    store.commit('setExerciseGraphics', response?.data.questions);

    return response?.data.questions;
  }

  getOptions() {
    if (store.getters.exerciseOptions.time) {
      return store.getters.exerciseOptions;
    }

    const options = {
      time: '5',
      howToShow: 'one',
      qtdQuestions: String(store.getters.exercise.questions.length)
    };

    store.commit('setExerciseOptions', options);

    return options;
  }

  shuffleQuestions(exercise: Record<string, any>, qtd: number) {
    const arrayShuffled = [];
    const newQuestions = exercise.questions;
    let optionsShuffled: Record<string, any> = [];
    let limit = qtd;

    for (let i = 0; i < qtd; i += 1) {
      optionsShuffled = [];

      const item = newQuestions.splice(Math.floor(Math.random() * limit), 1)[0];

      for (let j = item.options.length; j > 0; j -= 1) {
        const newItem = item.options.splice(Math.floor(Math.random() * j), 1)[0];
        newItem.value = false;
        optionsShuffled.push(newItem);
      }

      item.options = optionsShuffled;
      item.response = '';
      arrayShuffled.push(item);
      limit -= 1;
    }

    return arrayShuffled;
  }
}
