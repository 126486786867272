import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionPageExam } from '../Middleware/permission/PageExam';
import { permissionFeature } from '../Middleware/permission/Feature';
import { onboardingExam } from '../Middleware/onboarding';

export const NAME_ROUTES_EXAM = {
  LIST: 'Exams',
  SHOW: 'Exam',
  TUTORIAL: 'TutorialExam',
  ExamViewer: 'ExamViewer'
};

export const Exam: Array<RouteConfig> = [
  {
    path: '/vestibular',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_EXAM.LIST,
        component: () => import(/* webpackChunkName: "exam" */ '@/pages/Exams/index.vue'),
        meta: { title: 'Vestibulares' },
        beforeEnter: multiGuard([
          // permissionFeature,
          onboardingExam
        ])
      },
      {
        path: 'tutorial',
        name: NAME_ROUTES_EXAM.TUTORIAL,
        component: () => import(
          /* webpackChunkName: "exam-tutorial" */ '@/pages/Exams/pages/TutorialExam/index.vue'
        ),
        meta: { title: 'Tutorial Vestibulares' }
        // beforeEnter: permissionFeature,
      }
    ]
  },
  {
    path: '/vestibular',
    component: () => import('@/layouts/Sidebar/index.vue'),
    children: [
      {
        path: ':book/:idExam',
        name: NAME_ROUTES_EXAM.SHOW,
        component: () => import(/* webpackChunkName: "exam-show" */ '@/pages/Exams/pages/Exam/index.vue'),
        meta: { title: 'Vestibular Questões' },
        beforeEnter: multiGuard([
          // permissionFeature,
          // permissionPageExam,
        ])
      },
      {
        path: 'pdf/:idExam/:idFile/:slug',
        name: NAME_ROUTES_EXAM.ExamViewer,
        component: () => import(
          /* webpackChunkName: "exam-show-pdf" */ '@/pages/Exams/pages/ExamViewer/index.vue'
        ),
        meta: { title: 'Visualizador Vestibular' },
        beforeEnter: multiGuard([permissionFeature, permissionPageExam])
      }
    ]
  }
];
