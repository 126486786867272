// eslint-disable-next-line import/no-cycle
import BookService from '../../services/Book/BookService';
// eslint-disable-next-line import/no-cycle
import VideoService from '../../services/Video/VideoService';
// eslint-disable-next-line import/no-cycle
import HistoricService from '../../services/Historic/historicService';

const bookService = new BookService();
const historicService = new HistoricService();
const videoService = new VideoService();

const getDefaultState = () => ({
  allChecked: {
    videos: [],
    archives: [],
    exercices: []
  },
  ids: {
    videos: [],
    archives: [],
    exercices: []
  },
  files: {
    videos: [],
    archives: [],
    exercices: []
  },
  indexPlanner: []
});

const PlannerAccordion = {
  state: getDefaultState(),
  mutations: {
    resetState(state: Record<string, any>) {
      Object.assign(state, getDefaultState());
    },
    setPlannerAccordionIdsVideos(state: Record<string, any>, payload: Array<number>) {
      state.ids.videos = [...state.ids.videos, ...payload];
    },
    setPlannerAccordionIdsArchives(state: Record<string, any>, payload: Array<number>) {
      state.ids.archives = [...state.ids.archives, ...payload];
    },
    setPlannerAccordionAllCheckedVideos(
      state: Record<string, any>,
      payload: Array<Record<string, any>>
    ) {
      state.allChecked.videos = [...state.allChecked.videos, ...payload];
    },
    setPlannerAccordionAllCheckedArchives(
      state: Record<string, any>,
      payload: Array<Record<string, any>>
    ) {
      state.allChecked.archives = [...state.allChecked.archives, ...payload];
    },
    setPlannerAccordionAllCheckedArchivesEvery(
      state: Record<string, any>,
      payload: Array<Record<string, any>>
    ) {
      state.allChecked.archives = payload;
    },
    setPlannerAccordionFilesVideos(
      state: Record<string, any>,
      payload: Array<Record<string, any>>
    ) {
      state.files.videos = [...state.files.videos, ...payload];
    },
    setPlannerAccordionFilesArchives(
      state: Record<string, any>,
      payload: Array<Record<string, any>>
    ) {
      state.files.archives = [...state.files.archives, ...payload];
    },
    setIndexPlanner(state: Record<string, any>, payload: number) {
      state.indexPlanner = [...state.indexPlanner, payload];
    },
    setPlannerClearIds(state: Record<string, any>, payload: number) {
      state.ids = payload;
    }
  },
  actions: {
    async loaderData(context: Record<string, any>, payload: Record<string, any>) {
      context.commit('setPlannerClearIds', {
        videos: [],
        archives: [],
        exercices: []
      });
      const foundId = context.state.indexPlanner.includes(payload.index);

      if (!foundId) {
        context.dispatch('setIds', payload.week);

        await context.dispatch('getFilesVideos', context.state.ids.videos);
        await context.dispatch('getFilesArchives', context.state.ids.archives);
        await context.dispatch('getAllVideos', context.state.ids.videos);
        await context.dispatch('getAllMaterials', context.state.ids.archives);
        context.commit('setIndexPlanner', payload.index);
      }
    },
    setIds(context: Record<string, any>, payload: Array<Record<string, any>>) {
      // eslint-disable-next-line no-unused-expressions
      payload?.forEach((discipline: Record<string, any>) => {
        // eslint-disable-line
        context.dispatch('getIds', {
          data: discipline,
          param: 'videos',
          idParam: 'videos',
          idType: 'idVideo'
        });
        context.dispatch('getIds', {
          data: discipline,
          param: 'archives',
          idParam: 'archives',
          idType: 'idArchive'
        });
        // context.dispatch('getIds', {
        //   data: discipline, param: 'exercices', idParam: 'exercices', idType: 'idExercice',
        // });
      });
    },
    getIds(context: Record<string, any>, payload: Record<string, any>) {
      if (!payload.data.topics || !payload.data.topics.length) {
        if (payload.data[payload.param] && payload.data[payload.param].length) {
          const ids = payload.data[payload.param].map(
            (item: Record<string, any>) => item[payload.idType]
          );
          if (payload.idParam === 'videos') {
            context.commit('setPlannerAccordionIdsVideos', ids);
          }
          if (payload.idParam === 'archives') {
            context.commit('setPlannerAccordionIdsArchives', ids);
          }
        }
      } else {
        payload.data.topics.forEach((topic: Record<string, any>) => {
          context.dispatch('getIds', {
            data: topic,
            param: payload.param,
            idParam: payload.idParam,
            idType: payload.idType
          });
        });
      }
    },
    async getFilesVideos(context: Record<string, any>, payload: Array<number>) {
      if (payload && payload.length > 99) {
        const response = await videoService.getVideos(payload.slice(0, 100));
        context.commit('setPlannerAccordionFilesVideos', response);
        await context.dispatch('getFilesVideos', payload.slice(100));
      } else if (payload && payload.length) {
        const response = await videoService.getVideos(payload);
        context.commit('setPlannerAccordionFilesVideos', response);
      }
    },
    async getFilesArchives(context: Record<string, any>, payload: Array<number>) {
      if (payload && payload.length > 99) {
        const response = await bookService.getArchives(payload.slice(0, 100));
        context.commit('setPlannerAccordionFilesArchives', response);
        await context.dispatch('getFilesArchives', payload.slice(100));
      } else if (payload && payload.length) {
        const response = await bookService.getArchives(payload);
        context.commit('setPlannerAccordionFilesArchives', response);
      }
    },
    async getAllVideos(context: Record<string, any>, payload: Array<number>) {
      if (payload && payload.length > 99) {
        const response = (await historicService.getHistoric({ type: 'video', listIds: payload.slice(0, 100) }))
          || [];

        context.commit('setPlannerAccordionAllCheckedVideos', response);
        await context.dispatch('getAllVideos', payload.slice(100));
      } else if (payload && payload.length) {
        const response = (await historicService.getHistoric({ type: 'video', listIds: payload })) || [];

        context.commit('setPlannerAccordionAllCheckedVideos', response);
      }
    },
    async getAllMaterials(context: Record<string, any>, payload: Array<number>) {
      if (payload && payload.length > 99) {
        const response = (await historicService.getHistoric({
          type: 'materiais',
          listIds: payload.slice(0, 100)
        })) || [];

        context.commit('setPlannerAccordionAllCheckedArchives', response);
        await context.dispatch('getAllMaterials', payload.slice(100));
      } else if (payload && payload.length) {
        const response = (await historicService.getHistoric({ type: 'materiais', listIds: payload })) || [];

        context.commit('setPlannerAccordionAllCheckedArchives', response);
      }
    }
  },
  getters: {
    plannerAccordionIds: (state: Record<string, any>) => state.ids,
    plannerAccordionAllChecked: (state: Record<string, any>) => state.allChecked,
    plannerAccordionFiles: (state: Record<string, any>) => state.files
  }
};

export default PlannerAccordion;
