// Modal apresentado nas trocas de rotas
export const URLS_PLAN_FREE = {
  E_MUITO_MAIS:
    'https://pages.explicae.com.br/planos/avancado?utm_source=plataformafree&utm_medium=modalavancado&utm_term=emuitomais',
  ASSINAR_PLANO:
    'https://pages.explicae.com.br/planos/avancado?utm_source=plataformafree&utm_medium=modalavancado',
  VER_OUTROS_PLANOS:
    'https://pages.explicae.com.br/planos/avancado?utm_source=plataformafree&utm_medium=modalavancado&utm_term=veroutrosplanos'
};

// Modal da página de Vestibulares
export const URLS_EXAM = {
  E_MUITO_MAIS:
    'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=modalvestibulares&utm_term=emuitomais',
  ASSINAR_PLANO:
    'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=modalvestibulares',
  VER_OUTROS_PLANOS:
    'https://pages.explicae.com.br/compare-os-planos?utm_source=plataformafree&utm_medium=modalvestibulares&utm_term=veroutrosplanos'
};

// Modal da página de Simulados
export const URLS_SIMULATED = {
  E_MUITO_MAIS:
    'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=modalsimulados&utm_term=emuitomais',
  ASSINAR_PLANO:
    'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=modalsimulados',
  VER_OUTROS_PLANOS:
    'https://pages.explicae.com.br/compare-os-planos?utm_source=plataformafree&utm_medium=modalsimulados&utm_term=veroutrosplanos'
};

// Página do Planner
export const URLS_PLANNER = {
  E_MUITO_MAIS:
    'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=featureplanner&utm_term=emuitomais',
  ASSINAR_PLANO:
    'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=featureplanner',
  VER_OUTROS_PLANOS:
    'https://pages.explicae.com.br/compare-os-planos?utm_source=plataformafree&utm_medium=featureplanner&utm_term=veroutrosplanos'
};

// Sidebar PLANNER
export const URL_QUERO_ASSINAR_PLANO_PLANNER = 'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=featureplanner&utm_term=queroassinarumplano';

// Página Redação
export const URLS_REDACTION = {
  E_MUITO_MAIS:
    'https://pages.explicae.com.br/redacao?utm_source=plataformafree&utm_medium=featureredacao&utm_term=emuitomais#pricing',
  ASSINAR_PLANO: 'https://carrinho.explicae.com.br/comprar/1270/semester',
  VER_OUTROS_PLANOS:
    'https://pages.explicae.com.br/redacao?utm_source=plataformafree&utm_medium=featureredacao&utm_term=veroutrosplanos#pricing'
};

// URL do botão no componente de créditos para cada contexto
export const URL_BUTTON_CREDIT_BOOK = 'https://pages.explicae.com.br/compare-os-planos?utm_source=plataformafree&utm_medium=creditocaderno';

export const URL_BUTTON_CREDIT_QUESTION = 'https://pages.explicae.com.br/planos/intermediario?utm_source=plataformafree&utm_medium=creditoquestoes';
