import { NavigationGuardNext, Route } from 'vue-router';

import { LIST_PERMISSION } from '@/constant/ListPermission';

import Permission from './index';

import { NAME_ROUTES_DASHBOARD } from '../../routes/Dashboard';

export function permissionQuestion(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const PermissionClass = new Permission();

  const verifyPermissionQuestion = PermissionClass.can(LIST_PERMISSION.BOOKS_DEGREE_ELEMENTARY_SCHOOL_II)
    || PermissionClass.can(LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL);

  if (!verifyPermissionQuestion) {
    next({
      name: NAME_ROUTES_DASHBOARD.DASHBOARD
    });

    return;
  }

  next();
}
