import { NavigationGuardNext, Route } from 'vue-router';

import { hasPermissionMaterials } from '@/share/Util/Permissions/materials';

import { Material } from '@/globalInterfaces/Material';

import BookService from '@/services/Book/BookService';

const bookService = new BookService();

async function getArchive(archiveId: number) {
  let archive: Material | null = null;

  try {
    [archive] = await bookService.getArchives([archiveId]);
  } catch (error) {
    console.error(error);
  }

  return archive;
}

export async function permissionArchive(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const { fileID } = to.params;

  const archive = await getArchive(Number(fileID));

  const hasPermissionArchive = archive ? hasPermissionMaterials(archive) : false;

  if (hasPermissionArchive) {
    next();
    return;
  }

  next({
    name: 'Dashboard'
  });
}
