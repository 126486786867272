import { Component, Vue } from 'vue-property-decorator';

import BookService from '@/services/Book/BookService';

import { Book as IBook } from '@/globalInterfaces/Book';

interface Search {
  content: string;
  type: string;
  context: string;
  bookId: number;
  bookTitle: string;
  workspace: string;
  topicId: number;
  topicTitle: string;
  bigtext: string;
  details: {
    type: string;
    id: number;
  };
}

@Component
export default class SearchBook extends Vue {
  protected searchList: Array<Search> = [];
  protected searchNotFound = '';

  protected BookService = new BookService();

  async onSearchChange(searchValue: string, idBook?: number) {
    this.searchNotFound = '';
    this.searchList = [];

    if (searchValue.length) {
      const response = await this.BookService.getSearch(searchValue, idBook);

      if (!response || !response.length) {
        this.searchNotFound = `Não foram encontrados resultados para '${searchValue}'`;
        return;
      }

      const newListSearch: Array<Search> = [];

      response.forEach((item: Search) => {
        newListSearch.push({
          ...item,
          content: `${item?.bookTitle} - ${item?.topicTitle}: ${item?.content}`
        });
      });

      this.searchList = newListSearch;
    }
  }

  async searchLink(item: Search) {
    if (item?.bookId) {
      const bookID = item.bookId;
      const topicID = item.topicId;
      const fileID = item.details.id;
      let slugFile = item.content
        .toLowerCase()
        .split(' ')
        .join('-');

      slugFile = this.removeAccents(slugFile);

      if (item.details.type === 'archives') {
        const response = await this.BookService.getArchives([fileID]);
        if (response && response.length) {
          this.downloadArchive(response[0].url, response[0].title);
        }
        return;
      }

      const allBooks = await this.BookService.books(
        undefined,
        this.$store.getters.profile?.institution?.id
      );
      let bookCurrent: IBook | any = null;

      bookCurrent = allBooks.find((book: IBook) => book.idBooks === bookID);

      const slugBook = bookCurrent.slug;

      if (item.details.type === 'videos') {
        this.$router.push({ path: `/videos/caderno/${slugBook}/${topicID}/${fileID}/${slugFile}` });
        return;
      }

      if (item.details.type === 'exercices') {
        this.$router.push({
          path: `/exercicios/caderno/${slugBook}/${topicID}/${fileID}/${slugFile}`
        });
      }
    }
  }

  removeAccents(slugFile: string) {
    const slugFileAccents = slugFile.split('');
    const slugFileAccentsOut: string[] = [];
    const slugFileAccentsLength = slugFileAccents.length;
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

    for (let y = 0; y < slugFileAccentsLength; y += 1) {
      const index = accents.indexOf(slugFileAccents[y]);

      slugFileAccentsOut[y] = index !== -1 ? accentsOut[index] : slugFileAccents[y];
    }

    return slugFileAccentsOut.join('');
  }

  downloadArchive(url: string, title: string) {
    const a = document.createElement('a') as HTMLAnchorElement;

    a.href = `https://explicae.com.br/app/${url}`;

    a.download = title;

    a.target = '_blank';

    (document.body || document.documentElement).appendChild(a);
    a.click();
    // @ts-ignore
    a.parentNode.removeChild(a);
  }
}
