import store from '@/store';
import AppClient from '../AppClient';

import { IMyRedactions, ISaveRedaction, IRedactionsNote } from './IRedaction';

export default class RedactionService {
  private basePathRedaction = process.env.VUE_APP_REDACTION_API;

  private Client = new AppClient({ url: this.basePathRedaction });

  async getMyRedaction(data: IMyRedactions) {
    const URI = '/redactions/me';

    const response = await this.Client.get(URI, data);

    return response?.data;
  }

  async getRedactionDescription(idTheme: number) {
    const URI = `/themes/${idTheme}`;

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getThemesMostPopular(limit?: number) {
    const URI = '/themes/most/popular';

    const response = await this.Client.get(URI, { ...(limit && { limit }) });

    return response?.data;
  }

  async getRedactionsByCategory() {
    const URI = '/themes/categories';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getMyPerformance() {
    const URI = '/redactions/overview';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getRedactionsHundred(data: IRedactionsNote) {
    const URI = '/redactions/hundred';

    const response = await this.Client.get(URI, {
      ...data,
      logged: true
    });

    return response?.data;
  }

  async getCorrectionRedaction(redactionID: number) {
    const URI = `/redactions/${redactionID}`;

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async saveRedaction(data: ISaveRedaction) {
    const URI = '/redactions';

    const response = await this.Client.post(URI, data);

    return response;
  }

  async getTotalRedactionSend() {
    if (store.getters.totalRedactionsMeMonthly !== null) {
      return {
        data: {
          total_redactions_me_monthly: store.getters.totalRedactionsMeMonthly
        }
      };
    }

    const URI = '/redactions/total';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getThemeWeek() {
    const URI = '/themes/highlight';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getThemesOfSimulated() {
    const URI = '/themes/simulated';

    const { themesOfSimulated } = store.getters;

    if (themesOfSimulated.length) return themesOfSimulated;

    const response = await this.Client.get(URI);

    store.commit('setThemesOfSimulated', response?.data);

    return response?.data;
  }
}
