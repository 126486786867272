import { Component, Vue } from 'vue-property-decorator';

import { NewCourse } from '@/globalInterfaces/Course';
import { ProductCard, ProductData } from '@/globalInterfaces/ProductCard';

import { LIST_PRODUCTS_FOR_SALE } from '@/constant/SaleOfProducts';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';

@Component
export default class SetSaleOfProducts extends Vue {
  protected listAllProductsForSale: Array<ProductData> | [] = LIST_PRODUCTS_FOR_SALE;
  protected currentTabProductList: ProductData | {} = {};

  created() {
    [this.currentTabProductList] = this.listAllProductsForSale;
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan.planID === LIST_PLAN_ID.FREE;
  }

  filterCoursesForSaleWithoutAccess(listCoursesWithAccess: Array<NewCourse>) {
    // if (listCoursesWithAccess && listCoursesWithAccess.length) {
    //   const ID_SALE_COURSES = 'courses';
    //   let filteredCoursesForSale;

    //   LIST_PRODUCTS_FOR_SALE.forEach((product: ProductData) => {
    //     if (product.id === ID_SALE_COURSES) {
    //       filteredCoursesForSale = product.products.filter((courseProduct: ProductCard) => !listCoursesWithAccess.some((course: NewCourse) => courseProduct.ids.every((id: number) => course.id === id)));
    //     }
    //   });

    //   if (filteredCoursesForSale !== undefined) {
    //     this.setAllProductsForSale(filteredCoursesForSale, ID_SALE_COURSES);
    //   }

    //   return;
    // }

    this.listAllProductsForSale = LIST_PRODUCTS_FOR_SALE;
    this.changeCurrentProductListInTab();
  }

  setAllProductsForSale(filteredProductsForSale: Array<ProductCard>, idProduct: string) {
    this.listAllProductsForSale.forEach((product: ProductData, index) => {
      if (product.id === idProduct) {
        if (filteredProductsForSale.length) {
          this.listAllProductsForSale[index].products = filteredProductsForSale;
        } else {
          this.listAllProductsForSale = this.listAllProductsForSale.filter(
            (productSale) => productSale.id !== idProduct
          );
        }
      }
    });

    this.changeCurrentProductListInTab(idProduct);
  }

  changeCurrentProductListInTab(idProduct?: string) {
    this.currentTabProductList = this.listAllProductsForSale.find((product: ProductData) => product.id === idProduct)
      || this.listAllProductsForSale[0];
  }

  redirectToBuyProduct(productSelected: ProductCard) {
    const CATEGORY_PRODUCT = 'PRODUTO';

    if (productSelected.category === CATEGORY_PRODUCT || this.isPlanFree) {
      window.open(productSelected.link, '_blank');
      return;
    }

    const textMessageWhatsApp = `Olá! Quero adicionar o curso ${productSelected.title} ao meu plano.`;
    window.open(
      `https://api.whatsapp.com/send?phone=557981093809&text=${textMessageWhatsApp}`,
      '_blank'
    );
  }
}
