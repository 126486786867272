
































import { Component, Mixins, Watch } from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ThemeWeek from '@/components/ThemeWeek/ThemeWeek.vue';
import MyPerformanceRedaction from '@/components/MyPerformanceRedaction/MyPerformanceRedaction.vue';
import ModalCreditIsOver from '@/components/Redaction/Modals/ModalCreditIsOver.vue';

import Permission from '@/mixins/permission';

import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PERMISSION } from '@/constant/ListPermission';

import { Redaction, RedactionSent } from '@/globalInterfaces/Redaction';

const STATUS_REDACTION_PENDENT = 'PENDENT';
const STATUS_REDACTION_CORRECTED = 'CORRECTED';
const STATUS_REDACTION_REJECTED = 'REJECTED';

@Component({
  components: {
    BoxContainer,
    ThemeWeek,
    MyPerformanceRedaction,
    ModalCreditIsOver
  }
})
export default class ContainerRedation extends Mixins(Permission) {
  private lastRedactionSent: Redaction | null = null;

  private setModal = new ActiveModal();

  created() {
    this.getLastRedactionSent();
  }

  @Watch('lastRedactionSent', { deep: true })
  @Watch('hasPermission', { immediate: true })
  emitShowComponent() {
    this.$emit('show-component', this.lastRedactionSent || this.hasPermission);
  }

  get setClassesContainer() {
    if (this.hasPermission && !this.lastRedactionSent) return '-without-last-redaction';
    if (!this.hasPermission && this.lastRedactionSent) return '-without-redaction-theme__week';
    return '';
  }

  get hasPermission() {
    return this.can(LIST_PERMISSION.REDACTION_CREDITS);
  }

  get getTotalCreditsRedaction(): number | null {
    const total = this.getQuantityPermission(LIST_PERMISSION.REDACTION_CREDITS);
    return total;
  }

  get getRemainingCreditsRedaction() {
    const totalCredits = this.getTotalCreditsRedaction;
    if (!totalCredits) return 0;

    const resultCredits = totalCredits - this.totalRedactionMeMonthly;
    return resultCredits < 0 ? 0 : resultCredits;
  }

  get totalRedactionMeMonthly() {
    return Number(this.$store.getters.totalRedactionsMeMonthly);
  }

  get statusRedaction() {
    return {
      STATUS_REDACTION_PENDENT,
      STATUS_REDACTION_CORRECTED,
      STATUS_REDACTION_REJECTED
    };
  }

  get showButtonRejected() {
    if (!this.lastRedactionSent) return false;
    return (
      this.lastRedactionSent?.status === this.statusRedaction.STATUS_REDACTION_REJECTED
      || !this.lastRedactionSent.id
    );
  }

  async getLastRedactionSent() {
    try {
      const PAGE_REDACTION = 0;

      const response = await this.RedactionService.getMyRedaction({
        page: PAGE_REDACTION
      });

      if (response?.redactions.length) {
        this.lastRedactionSent = this.setDataRedaction(response?.redactions[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setDataRedaction(redaction: RedactionSent) {
    if (redaction) {
      return {
        id: redaction?.id.toString(),
        theme: redaction?.theme?.title,
        status: redaction?.status,
        score: redaction?.score,
        rejection_cause: redaction?.rejection_cause
      };
    }

    return null;
  }

  goToMyRedactions() {
    this.$router.push({
      name: 'MyRedactions'
    });
  }

  showModalCreditIsOver() {
    this.setModal.activeModal('ModalCreditIsOver');
  }
}
