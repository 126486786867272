const Simulator = {
  state: {
    optionsSimulator: []
  },
  mutations: {
    setOptionsSimulator(state: Record<string, any>, payload: Record<string, any>) {
      state.optionsSimulator = payload;
    }
  },
  getters: {
    optionsSimulator: (state: Record<string, any>) => state.optionsSimulator
  }
};

export default Simulator;
