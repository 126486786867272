import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_MAINTENANCE = {
  MAINTENANCE: 'Maintenance',
  PROBLEM: 'Problem',
  INSTABILITY: 'Instability'
};

export const Maintenance: Array<RouteConfig> = [
  {
    path: '/manutencao',
    component: () => import('@/layouts/Feedback/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_MAINTENANCE.MAINTENANCE,
        component: () => import(/* webpackChunkName: "maintenance" */ '@/pages/Maintenance/Maintenance.vue'),
        meta: { title: 'Manutenção' }
      }
    ]
  },
  {
    path: '/problema',
    component: () => import('@/layouts/Feedback/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_MAINTENANCE.PROBLEM,
        component: () => import(/* webpackChunkName: "problem" */ '@/pages/Problem/Problem.vue'),
        meta: { title: 'Problema' }
      }
    ]
  },
  {
    path: '/instabilidade',
    component: () => import('@/layouts/Feedback/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_MAINTENANCE.INSTABILITY,
        component: () => import(/* webpackChunkName: "instability" */ '@/pages/Instability/Instability.vue'),
        meta: { title: 'Instabilidade' }
      }
    ]
  }
];
