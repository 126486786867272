















import { Component, Vue, Prop } from 'vue-property-decorator';

import { convertHexToHSL, modifyHslLuminosity } from '@/share/Util/Color/Color';
import { setImageProfile } from '@/share/Util/Image/Image';

const DEFAULT_AVATAR = 'https://cdn.explicae.com.br/public/platform/avatar/avatar.jpg';

@Component
export default class AvatarRanking extends Vue {
  @Prop({ default: DEFAULT_AVATAR }) avatar!: string;
  @Prop() color!: string;
  @Prop() title!: string;

  private DEFAULT_AVATAR = DEFAULT_AVATAR;

  get setAvatar() {
    return setImageProfile(this.avatar);
  }

  getUserAvatarStyle(hexColor: string) {
    const hslColor = convertHexToHSL(hexColor);

    const gradientColors = modifyHslLuminosity(hslColor, [20, 27, 0, 0, 27, 20]);

    const gradient = gradientColors.join(', ');

    return {
      background: `linear-gradient(180deg, ${gradient})`
    };
  }
}
