import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_RANKING = {
  RANKING: 'Ranking'
};

export const Ranking: Array<RouteConfig> = [
  {
    path: '/ranking',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_RANKING.RANKING,
        component: () => import(/* webpackChunkName: "ranking" */ '@/pages/Ranking/Ranking.vue'),
        meta: { title: 'Ranking' }
      }
    ]
  }
];
