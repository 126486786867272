const BreadCrumb = {
  state: {
    clickedBreadcrumb: false
  },
  mutations: {
    setClickedBreadcrumb(state: Record<string, any>, payload: boolean) {
      state.clickedBreadcrumb = payload;
    }
  },
  getters: {
    clickedBreadcrumb: (state: Record<string, any>) => state.clickedBreadcrumb
  }
};

export default BreadCrumb;
