


























import { Component, Vue, Watch } from 'vue-property-decorator';

import {
  differenceInDays,
  endOfDay,
  compareAsc,
  addHours
} from 'date-fns';

import ExToastify from '@/components/Toastify/Toastify.vue';

import ModalReportProblem from '@/components/ButtonReportProblem/ModalReportProblem.vue';
import ModalCourse from '@/components/Modal/ModalCourse/ModalCourse.vue';
import ModalNotContentPlan from '@/components/Modal/ModalNotContentPlan/ModalNotContentPlan.vue';
import ModalExitSimulated from '@/components/Modal/ModalExitSimulation/ModalExitSimulation.vue';
import ModalTrialPlan from '@/components/Modal/ModalTrialPlan/ModalTrialPlan.vue';
import ModalWelcomePlanTrial from '@/components/Modal/ModalWelcomePlanTrial/ModalWelcomePlanTrial.vue';
import ModalPlanUpdate from '@/components/Modal/ModalPlanUpdate/ModalPlanUpdate.vue';
import ModalEndOfPlanTrial from '@/components/Modal/ModalEndOfPlanTrial/ModalEndOfPlanTrial.vue';
import ModalWelcomeFeature from '@/components/Modal/ModalWelcomeFeature/ModalWelcomeFeature.vue';
import ModalEndFeature from '@/components/Modal/ModalEndFeature/ModalEndFeature.vue';
import ModalPopUpPlanFree from '@/components/Modal/ModalPopUpPlanFree/ModalPopUpPlanFree.vue';
import ModalContentFree from '@/components/Modal/ModalContentFree/index.vue';
import ModalPopUpPlanFreeSimulated from '@/components/Modal/ModalPopUpPlanFree/ModalPopUpPlanFreeSimulated.vue';
import ModalPopUpPlanFreeExam from '@/components/Modal/ModalPopUpPlanFree/ModalPopUpPlanFreeExam.vue';
import ModalCredits from '@/components/Modal/ModalCredits/index.vue';
import ModalRankingPass from '@/components/Modal/ModalRankingPass/index.vue';
import ModalConsumerCredits from '@/components/Modal/ModalConsumerCredits/index.vue';

import ActiveModal from '@/share/Util/ActiveModal';
import Onboarding from '@/share/Util/Onboarding/Onboarding';

import ActiveModalRankingPass from '@/mixins/ActiveModalRankingPass';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';

const SEVEN_DAY_REMAINING = 7;
const ZERO_DAY_REMAINING = 0;

interface Permission {
  ID: number;
  end_date: string | Date;
  start_date: string | Date;
  isExtraPermission?: boolean;
  name: string;
}

@Component({
  components: {
    ExToastify,
    ModalReportProblem,
    ModalCourse,
    ModalNotContentPlan,
    ModalExitSimulated,
    ModalTrialPlan,
    ModalWelcomePlanTrial,
    ModalPlanUpdate,
    ModalEndOfPlanTrial,
    ModalWelcomeFeature,
    ModalEndFeature,
    ModalPopUpPlanFree,
    ModalContentFree,
    ModalPopUpPlanFreeSimulated,
    ModalPopUpPlanFreeExam,
    ModalCredits,
    ModalRankingPass,
    ModalConsumerCredits
  },
  mixins: [ActiveModalRankingPass]
})
export default class App extends Vue {
  private onboarding = new Onboarding();

  private modalInstance = new ActiveModal();

  get isLogged() {
    return this.profile?.name;
  }

  get profile() {
    return this.$store.getters.profile;
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanTrial() {
    return this.plan?.planID === LIST_PLAN_ID.TRIAL;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get daysRemainingPlan() {
    const planData = this.plan;
    const daysRemaining = differenceInDays(
      endOfDay(new Date(planData?.end_date)),
      new Date()
    );

    return daysRemaining;
  }

  @Watch('$route')
  init() {
    this.setModal();
  }

  @Watch('daysRemainingPlan')
  setModal() {
    if (!this.isLogged) return;
    if (
      this.isPlanTrial
      && this.daysRemainingPlan === SEVEN_DAY_REMAINING
      && !this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS_PLAN_TRIAL)) {
      this.modalInstance.activeModal('ModalWelcomePlanTrial');
      return;
    }

    if (this.daysRemainingPlan === ZERO_DAY_REMAINING && this.isPlanTrial) {
      this.modalInstance.activeModal('ModalTrialPlan');
      return;
    }

    if (
      this.isPlanFree
      && !this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS)
      && this.isPermissionTemporary()
    ) {
      this.modalInstance.activeModal('ModalWelcomeFeature');
      return;
    }

    if (
      this.isPlanFree
      && this.viewedModal(LIST_ONBOARDING_ID.FIRST_ACCESS)
      && !this.viewedModal(LIST_ONBOARDING_ID.PERMISSION_TIMEOUT)
      && this.isFirstPermissionTemporaryOver()
    ) {
      this.modalInstance.activeModal('ModalEndFeature');
    }
  }

  viewedModal(IdModal: number) {
    if (!this.profile?.name) {
      return false;
    }
    return this.onboarding.alreadyViewedOnboarding(IdModal);
  }

  isPermissionTemporary() {
    const permissions = this.$store.getters.profile?.permissions ?? [];

    if (!permissions.length) return false;

    return permissions.some((permission: Permission) => permission?.isExtraPermission);
  }

  isFirstPermissionTemporaryOver() {
    const permissions = this.$store.getters.profile?.permissions ?? [];

    if (!permissions.length) return false;

    const foundPermission = permissions.sort((permissionFirst: Permission, permissionSecond: Permission) => {
      const permissionFirstDate = new Date(permissionFirst?.start_date);
      const permissionSecondDate = new Date(permissionSecond?.start_date);

      return permissionFirstDate.getTime() - permissionSecondDate.getTime();
    }).find((permission: Permission) => permission?.isExtraPermission);

    const isOverPermission = compareAsc(addHours(new Date(foundPermission?.end_date), 3), new Date()) === -1;

    return foundPermission ? isOverPermission : false;
  }
}

