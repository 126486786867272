import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionBookFeature } from '../Middleware/permission/Book';
import { onboardingBooks } from '../Middleware/onboarding';

export const NAME_ROUTES_BOOK = {
  LIST: 'Books',
  SHOW: 'BooksView',
  TUTORIAL: 'TutorialBooks'
};

export const Book: Array<RouteConfig> = [
  {
    path: '/cadernos',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_BOOK.LIST,
        component: () => import(/* webpackChunkName: "book" */ '@/pages/Books/index.vue'),
        meta: { title: 'Cadernos' },
        beforeEnter: onboardingBooks
      },
      {
        path: ':path?/tutorial',
        name: NAME_ROUTES_BOOK.TUTORIAL,
        component: () => import(
          /* webpackChunkName: "books-tutorial" */ '@/pages/Books/pages/TutorialBooks/index.vue'
        ),
        meta: { title: 'Tutorial Cadernos' }
      }
    ]
  },
  {
    path: '/caderno',
    component: () => import('@/layouts/Sidebar/index.vue'),
    children: [
      {
        path: ':path',
        name: NAME_ROUTES_BOOK.SHOW,
        component: () => import(/* webpackChunkName: "book-show" */ '@/pages/Book/index.vue'),
        meta: { title: 'Caderno', breadcrumbs: 'Caderno/:path' },
        beforeEnter: multiGuard([permissionBookFeature, onboardingBooks])
      }
    ]
  }
];
