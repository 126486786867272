/**
 * PARA OS STATUS DE INFORMAÇÃO (1XX) UTILIZAMOS O PREFIXO: INFORMATIONAL
 * PARA OS STATUS DE SUCESSO (2XX) UTILIZAMOS O PREFIXO: SUCESS
 * PARA OS STATUS DE REDIRECIONAMENTO (3XX) UTILIZAMOS O PREFIXO: REDIRECTION
 * PARA OS STATUS DE ERRO DO CLIENTE (4XX) UTILIZAMOS O PREFIXO: CLIENT_ERROR
 * PARA OS STATUS DE ERRO DO SERVIDOR (5XX) UTILIZAMOS O PREFIXO: SERVER_ERROR
 */

export const STATUS_RESPONSE_API = {
  SUCCESS_OK: 200,
  SUCCESS_CREATED: 201,
  SUCCESS_NO_CONTENT: 204,
  CLIENT_ERROR_BAD_REQUEST: 400,
  CLIENT_ERROR_UNAUTHORIZED: 401,
  CLIENT_ERROR_FORBIDDEN: 403,
  CLIENT_ERROR_NOT_FOUND: 404,
  SERVER_ERROR_INTERNAL_ERROR: 500
};
