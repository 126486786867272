import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default class ReportSentry {
  private debug = process.env.NODE_ENV !== 'production';
  private tracesSampleRate = process.env.NODE_ENV === 'production' ? 0.2 : 1.0;
  private logErrors = process.env.NODE_ENV === 'production';

  initialization() {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.NODE_ENV,
      debug: this.debug,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: ['https://plataforma.explicae.com.br', /^\//]
        })
      ],
      tracingOptions: {
        trackComponents: true
      },
      tracesSampleRate: this.tracesSampleRate,
      logErrors: this.logErrors,
      attachProps: true,
      attachStacktrace: true
    });
  }

  sentryCaptureException(err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error({ err });
      return;
    }

    Sentry.captureException(err);
  }
}
