import { Mixins, Component } from 'vue-property-decorator';

import { NewCourse, CurrentCourse, LastAccessedContentCourse } from '@/globalInterfaces/Course';

import AccessCourse from '@/mixins/AccessCourses';
import generalBooks from '@/mixins/generalBooks';

import CoursesService from '@/services/Courses/CoursesService';
import HistoricService from '@/services/Historic/historicService';

import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

@Component
export default class CourseInProgress extends Mixins(AccessCourse, generalBooks) {
  protected loadingCourseInProgress = true;
  protected courseInProgress: NewCourse | Record<string, any> = {};
  protected lastAccessedContentCourse: LastAccessedContentCourse | Record<string, any> = {};
  protected currentWeekCourse: CurrentCourse | {} = {};
  protected listOfCoursesWithAccess: Array<NewCourse> = [];

  protected hasPermissionInCourses = false;
  private allCoursesWithPermisson: Array<NewCourse> = [];
  private listIdsCoursesPermission: Array<string> = [];

  private CoursesService = new CoursesService();
  private HistoricService = new HistoricService();

  get listOfAllowedCourses() {
    return this.allCoursesWithPermisson;
  }

  async getCourseInProgress() {
    try {
      this.loadingCourseInProgress = true;

      this.hasPermissionInCourses = this.verifyPermissionInCourses();

      if (this.hasPermissionInCourses) {
        await this.getDataCourses();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar curso em andamento.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.loadingCourseInProgress = false;
    }
  }

  verifyPermissionInCourses() {
    const listIdsCourses = Object.keys(IDS_FOR_PERMISSION_COURSE);

    this.listIdsCoursesPermission = listIdsCourses.filter((idCourse: string) => this.can(IDS_FOR_PERMISSION_COURSE[Number(idCourse)]));

    return this.listIdsCoursesPermission.length > 0;
  }

  async getDataCourses() {
    await this.getCoursesWithAccess();
    await this.getLastAccessedContentCourse();
  }

  async getCoursesWithAccess() {
    try {
      const allCoursesWithPermisson = await this.getAllCoursesWithPermission();
      this.allCoursesWithPermisson = this.setCourses(allCoursesWithPermisson);
    } catch (error) {
      console.error(error);
    }
  }

  async getLastAccessedContentCourse() {
    try {
      this.lastAccessedContentCourse = await this.HistoricService.getCourseAccessWeek();

      if (this.lastAccessedContentCourse) {
        await this.getBookCourseInProgress();
        await this.getLastWeekAccessed();
      }
    } catch (error) {
      if (error?.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND) return;
      console.error(error);
    }
  }

  async getBookCourseInProgress() {
    const courseInProgress = this.allCoursesWithPermisson.find(
      (course: NewCourse) => course.idCourses === this.lastAccessedContentCourse.idCourses
    );

    if (courseInProgress) {
      const newCourseInProgress = {
        ...courseInProgress,
        id: courseInProgress.idCourses,
        colorBook: courseInProgress.color,
        cover: courseInProgress.thumb,
        disable: false,
        permission: true,
        type: 'courses',
        firstAccess: this.checkCourseAccess(courseInProgress.idCourses)
      };

      this.courseInProgress = {
        ...newCourseInProgress,
        redirect: () => this.verifyFirstAccessCourse(newCourseInProgress)
      };
    }
  }

  async getLastWeekAccessed() {
    try {
      if (this.lastAccessedContentCourse) {
        const idCourse = this.lastAccessedContentCourse.idCourses.toString();
        const idClasses = this.lastAccessedContentCourse.idClasses.toString();

        const listCourseWeeks = await this.CoursesService.getClassWeekCourse(idCourse, idClasses);

        if (listCourseWeeks.length) {
          this.currentWeekCourse = this.findLastWeekAccessed(listCourseWeeks);
        }
      }
    } catch (error) {
      console.error({ error });
    }
  }

  async getAllCoursesWithPermission() {
    const listCourses = await this.CoursesService.getCourses();

    return listCourses.filter((course: NewCourse) => this.listIdsCoursesPermission.includes(course.idCourses.toString()));
  }

  findLastWeekAccessed(listCourseWeeks: Array<Record<string, any>>) {
    return (
      listCourseWeeks.find(
        (week: Record<string, any>) => week.id === this.lastAccessedContentCourse.idWeek
      ) || {}
    );
  }

  setCourses(courses: Array<NewCourse>) {
    return courses.map((course: NewCourse) => {
      let newCourse = {
        ...course,
        id: course.idCourses,
        colorBook: course.color,
        cover: course.thumb,
        disable: false,
        permission: true,
        type: 'courses',
        firstAccess: this.checkCourseAccess(course.idCourses)
      };

      newCourse = {
        ...newCourse,
        redirect: () => this.verifyFirstAccessCourse(newCourse)
      };

      return newCourse;
    });
  }
}
