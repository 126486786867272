

























import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import VideoThumb from '@/components/VideoThumb/index.vue';
import ExSlick from '@/components/Slick/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import ListVideosLoading from '@/components/ListVideosLoading/index.vue';

import HistoricService from '@/services/Historic/historicService';

import { VideosHistoric } from '@/globalInterfaces/Video';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';

@Component({
  components: {
    ContainerFluid,
    ListVideosLoading,
    VideoThumb,
    BoxContainer,
    ExSlick,
    FeedbackUser
  }
})
export default class LastListVideos extends Vue {
  @Prop({ default: '' }) title!: string;
  @Prop({ required: false }) bookSlug!: string;

  private isLoading = true;

  private videoList: Array<VideosHistoric> = [];

  private historicService = new HistoricService();

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  @Watch('bookSlug', {
    immediate: true
  })
  async getLastVideosWatched() {
    try {
      this.isLoading = true;
      this.$store.commit('setIsLoadingVideosWatched', true);

      const videoList = await this.historicService.getMyListVideosWatched({
        bookSlug: this.bookSlug
        // isFree: this.isPlanFree,
      });

      if (videoList.length) {
        this.videoList = videoList;

        if (!this.isPlanFree) return;

        const idsVideosViewed = this.getVideoIds(videoList);

        this.$store.commit('setIdsVideoWatched', idsVideosViewed);
      }
    } catch (error) {
      if (error?.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND) return;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as últimas aulas assistidas.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoading = false;
      this.$store.commit('setIsLoadingVideosWatched', false);
    }
  }

  getVideoIds(videos: Array<VideosHistoric>) {
    if (!videos.length) return [];

    return videos.map((video: VideosHistoric) => video.idobject);
  }

  async setRemoveVideoList(video: Record<string, any>) {
    this.videoList.forEach((item, index) => {
      if (item.idobject === video.idobject) {
        this.videoList.splice(index, 1);
      }
    });

    await this.historicService.saveHistoric({
      idobject: Number(video.idobject),
      type: 'video',
      list: false
    });
  }

  redirectToVideo(videoData: VideosHistoric) {
    if (!videoData) return;

    const {
      context, path, idTopic, slug 
    } = videoData.object;

    this.$router.push({
      name: 'videos',
      params: {
        context,
        path,
        topicID: idTopic,
        fileID: String(videoData.idobject),
        slugFile: slug
      }
    });
  }
}
