import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

export function setNumber(value: number) {
  return value < 10 ? `0${value}` : value;
}

export function formatDate(date: Date, needConfigureDate: boolean, formattedDate: string) {
  const newDate = new Date(date);
  const formatDateDefault = 'dd/LL';
  let ajustedDate;

  if (needConfigureDate) ajustedDate = newDate.setDate(newDate.getDate() + 1);

  const dayOfMonth = format(ajustedDate || newDate, formattedDate || formatDateDefault, {
    locale: pt
  });

  return dayOfMonth;
}

export function orderList(list: Array<Record<string, any>>, sortingCriterion: string) {
  return list.sort(
    (currentItem, nextItem) => currentItem[sortingCriterion] - nextItem[sortingCriterion]
  );
}

export function removeEmptyValues(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') {
      result[key] = obj[key];
    }
  });

  return result;
}

export function sleep(time = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}
