import { LIST_PERMISSION } from './ListPermission';

import {
  MATERIAL_CATEGORY_THEORETICAL,
  MATERIAL_CATEGORY_CONTENT_AND_QUESTION,
  MATERIAL_CATEGORY_REVISION,
  MATERIAL_CATEGORY_QUESTIONS_ENEM,
  MATERIAL_CATEGORY_QUESTIONS_VESTIBULARES,
  MATERIAL_CATEGORY_QUESTIONS_FUVEST,
  MATERIAL_CATEGORY_QUESTIONS_MEDICINA,
  MATERIAL_CATEGORY_SUMMARY,
  MATERIAL_CATEGORY_TEST,
  MATERIAL_CATEGORY_ANSWER
} from './CategoryMaterialId';

export const ID_MATERIAL_CATEGORY_FOR_PERMISSION: Record<number, Array<string>> = {
  [MATERIAL_CATEGORY_THEORETICAL]: [LIST_PERMISSION.HANDOUT_THEORETICAL],
  [MATERIAL_CATEGORY_CONTENT_AND_QUESTION]: [LIST_PERMISSION.HANDOUT_THEORETICAL],
  [MATERIAL_CATEGORY_REVISION]: [LIST_PERMISSION.HANDOUT_REVIEWS],
  [MATERIAL_CATEGORY_QUESTIONS_ENEM]: [LIST_PERMISSION.HANDOUT_ENEM],
  [MATERIAL_CATEGORY_QUESTIONS_FUVEST]: [LIST_PERMISSION.HANDOUT_FUVEST],
  [MATERIAL_CATEGORY_QUESTIONS_VESTIBULARES]: [LIST_PERMISSION.HANDOUT_VESTIBULAR],
  [MATERIAL_CATEGORY_QUESTIONS_MEDICINA]: [LIST_PERMISSION.HANDOUT_MEDICINE],
  [MATERIAL_CATEGORY_SUMMARY]: [LIST_PERMISSION.HANDOUT_SUMMARY],
  [MATERIAL_CATEGORY_TEST]: [LIST_PERMISSION.HANDOUT_TEST],
  [MATERIAL_CATEGORY_ANSWER]: [LIST_PERMISSION.HANDOUT_ANSWER]
};
