










































import { Component, Mixins } from 'vue-property-decorator';

import { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import ContainerVideos from '@/components/ContainerVideos/ContainerVideos.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import Ranking from '@/components/Ranking/index.vue';

import MyCourses from './components/MyCourses/MyCourses.vue';
import ContainerRedation from '../ContainerRedaction/ContainerRedaction.vue';

import Search from '@/widgets/Util/Search/index.vue';
import Paragraph from '@/widgets/Util/Paragraph/Paragraph.vue';
// import ListNews from '@/widgets/News/ListNews/ListNews.vue';
import SaleOfProducts from '@/widgets/Products/SaleOfProducts/SaleOfProducts.vue';
import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import searchBook from '@/mixins/searchBook';
import SetSaleOfProducts from '@/mixins/SetSaleOfProduts';

import { NewCourse } from '@/globalInterfaces/Course';
import { ProductData } from '@/globalInterfaces/ProductCard';

import { TAB_MENU_SALES } from '../../Constants';

@Component({
  components: {
    MyCourses,
    Search,
    ContainerRedation,
    ContainerVideos,
    Paragraph,
    RealtimeUsers,
    SaleOfProducts,
    // ListNews,
    ContainerFluid,
    Ranking
  }
})
export default class CourseDashboard extends Mixins(searchBook, SetSaleOfProducts) {
  private showComponentRedaction = true;

  private tabMenuSales: Array<TabsMenuInterface> = [];

  adjustCoursesForSale(listOfCoursesWithAccess: Array<NewCourse>) {
    this.filterCoursesForSaleWithoutAccess(listOfCoursesWithAccess);
    this.setTabSales();
  }

  setTabSales() {
    if (this.listAllProductsForSale.length) {
      this.tabMenuSales = TAB_MENU_SALES.filter(
        (tab: TabsMenuInterface) => this.listAllProductsForSale.findIndex(
          (product: ProductData) => product.id === tab.slug
        ) !== -1
      );

      this.tabMenuSales[0].active = true;
    }
  }

  setShowComponentRedaction(value: boolean) {
    this.showComponentRedaction = value;
  }
}
