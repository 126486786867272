











import { Component, Prop, Vue } from 'vue-property-decorator';

const COLORS = ['#6E04C0', '#9205E0', '#A505F0', '#C04FF5', '#ECCBFC'];

@Component
export default class Confetti extends Vue {
  @Prop({ default: 100 }) confettiCount!: number;

  colors: string[] = COLORS;

  getConfettiStyle() {
    const color = this.colors[Math.floor(Math.random() * this.colors.length)];
    const left = `${Math.random() * 100}vw`;
    const animationDuration = `${Math.random() * 8 + 4}s`;
    const size = `${Math.random() * 10 + 5}px`;

    return {
      backgroundColor: color,
      left,
      animationDuration,
      width: size,
      height: size
    };
  }
}
