































import { Component, Mixins } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';

import { OnboardingClass } from '@/mixins/Onboarding';

@Component({
  components: {
    Modal
  }
})
export default class ModalEndFeature extends Mixins(OnboardingClass) {
  async onboardingWelcomeUpdate() {
    try {
      await this.updateOnboarding(LIST_ONBOARDING_ID.PERMISSION_TIMEOUT);
      await this.timerUpdateProfile();
      this.redirectDashboard();
    } catch (error) {
      console.error(error);
    }
  }

  redirectDashboard() {
    this.$router.push({
      name: 'Dashboard'
    });
  }
}
