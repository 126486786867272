import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionFeature } from '../Middleware/permission/Feature';
import { onboardingCourse } from '../Middleware/onboarding';

export const NAME_ROUTES_COURSE = {
  LIST: 'CoursesList',
  SHOW: 'Courses',
  TUTORIAL: 'TutorialCourse'
};

export const Course: Array<RouteConfig> = [
  {
    path: '/cursos',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_COURSE.LIST,
        component: () => import(
          /* webpackChunkName: "course-list" */ '@/pages/Courses/pages/ListCourses/ListCourses.vue'
        ),
        meta: { title: 'Cursos' },
        beforeEnter: multiGuard([permissionFeature, onboardingCourse])
      },
      {
        path: 'tutorial/:id?',
        name: NAME_ROUTES_COURSE.TUTORIAL,
        component: () => import(
          /* webpackChunkName: "course-tutorial" */ '@/pages/Courses/pages/TutorialCourse/TutorialCourse.vue'
        ),
        meta: { title: 'Tutorial Cursos' },
        beforeEnter: multiGuard([
          permissionFeature
          // permissionCoursePage,
        ])
      }
    ]
  },
  {
    path: '/cursos',
    component: () => import('@/layouts/Sidebar/index.vue'),
    children: [
      {
        path: ':slugCourse/:idCourses/:idClass',
        name: NAME_ROUTES_COURSE.SHOW,
        component: () => import(/* webpackChunkName: "course" */ '@/pages/Courses/Courses.vue'),
        meta: { title: 'Cronograma do Curso' },
        beforeEnter: multiGuard([
          permissionFeature,
          // permissionCoursePage,
          onboardingCourse
        ])
      }
    ]
  }
];
