



































import { Component, Mixins, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Banner from '@/components/Banner/Banner.vue';
import MainDashboard from './components/MainDashboard/MainDashboard.vue';
import CourseDashboard from './components/CourseDashboard/CourseDashboard.vue';
import RedactionDashboard from './components/RedactionDashboard/RedactionDashboard.vue';
import CourseInProgress from './components/CourseInProgress/CourseInProgress.vue';
import Footer from '@/components/Footer/Footer.vue';

import ModalVideo from '@/components/Modal/ModalVideo/ModalVideo.vue';

// eslint-disable-next-line import/no-cycle
import CourseInProgressMixin from '@/mixins/CourseInProgress';

import isPlanCoursePermission from '@/share/Util/Permissions/planCourse';
import ActiveModal from '@/share/Util/ActiveModal';

import { IDS_FOR_PERMISSION_COURSE } from '@/constant/CoursesId';
import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_PERMISSION } from '@/constant/ListPermission';

const DASHBOARD_COURSE = 'CourseDashboard';
const DASHBOARD_DEFAULT = 'MainDashboard';
const DASHBOARD_REDACTION = 'RedactionDashboard';

@Component({
  components: {
    PageHeader,
    Banner,
    MainDashboard,
    CourseDashboard,
    RedactionDashboard,
    CourseInProgress,
    Footer,
    ModalVideo
  }
})
export default class Dashboard extends Mixins(CourseInProgressMixin) {
  private modalInstance = new ActiveModal();

  async created() {
    await this.getCourseInProgress();
  }

  mounted() {
    this.showModal();
  }

  get hasPermissionCoursesInPlan() {
    const listPermissionCourse = Object.values(IDS_FOR_PERMISSION_COURSE);

    return listPermissionCourse.some((namePermission: string) => this.can(namePermission));
  }

  get hasCourseInProgress() {
    return (
      this.courseInProgress
      && Object.entries(this.courseInProgress).length
      && this.lastAccessedContentCourse
      && this.currentWeekCourse
    );
  }

  get planDashboardComponent() {
    if (this.verifyPlan.course) return DASHBOARD_COURSE;
    if (this.verifyPlan.redaction) return DASHBOARD_REDACTION;
    return DASHBOARD_DEFAULT;
  }

  get verifyPlan() {
    return {
      free: LIST_PLAN_ID.FREE === this.planProfile,
      redaction: LIST_PLAN_ID.REDACTION.includes(this.planProfile),
      course: isPlanCoursePermission()
    };
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get planProfile() {
    return this.$store.getters.profile?.plan?.planID;
  }

  get isHighSchool() {
    return this.can(LIST_PERMISSION.BOOKS_DEGREE_HIGH_SCHOOL);
  }

  get routeVideoID() {
    return this.$route.query.videoID;
  }

  @Watch('routeVideoID')
  showModal() {
    if (!this.routeVideoID) return;

    this.modalInstance.activeModal('ModalVideo');
  }
}
