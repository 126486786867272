const Profile = {
  state: {
    tabProfile: 0,
    profileMenu: [],
    notifications: [],
    notificationDataModalRankingPass: {},
    quantityNotifications: 0,
    isShowNotification: true
  },
  mutations: {
    setTabProfile(state: Record<string, any>, payload: number) {
      state.tabProfile = payload;
    },
    setProfileMenu(state: Record<string, any>, payload: Array<string>) {
      state.profileMenu = payload;
    },
    setNotifications(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.notifications = payload;
    },
    setQuantityNotifications(state: Record<string, any>, payload: number) {
      state.quantityNotifications = payload;
    },
    setIsShowNotification(state: Record<string, any>, payload: boolean) {
      state.isShowNotification = payload;
    },
    setNotificationDataModalRankingPass(state: Record<string, any>, payload: Record<string, any>) {
      state.notificationDataModalRankingPass = payload;
    }
  },
  getters: {
    tabProfile: (state: Record<string, any>) => state.tabProfile,
    profileMenu: (state: Record<string, any>) => state.profileMenu,
    notifications: (state: Record<string, any>) => state.notifications,
    quantityNotifications: (state: Record<string, any>) => state.quantityNotifications,
    isShowNotification: (state: Record<string, any>) => state.isShowNotification,
    notificationDataModalRankingPass: (state: Record<string, any>) => state.notificationDataModalRankingPass
  }
};

export default Profile;
