import Vue from 'vue';

import { CurrencyNumberFormat } from '@/share/Util/NumberFormat';

Vue.filter('formatPrice', (value: number) => {
  if (!value && value !== 0) return '';

  return CurrencyNumberFormat().format(value);
});

Vue.filter('formatResource', (num: number) => {
  if (!num && num !== 0) return '';

  if (num >= 1000000) {
    return `${(num / 1000000).toLocaleString('pt-BR')} milhão ${(num >= 2000000 ? 's' : '')}`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toLocaleString('pt-BR')} mil`;
  }
  return num.toLocaleString('pt-BR');
});
