import { foundPermission, validatePermissionDate } from './Permission';

import { IDS_FOR_PERMISSION_SIMULATED, LIST_PERMISSION_SIMULATED } from '@/constant/Simulated';

function hasPermissionSimulated() {
  const hasPermissionIdSimulated = LIST_PERMISSION_SIMULATED.some((permissionName: string) => {
    const permission = foundPermission(permissionName);

    if (!permission) return false;

    return validatePermissionDate(permission);
  });

  return hasPermissionIdSimulated;
}

function hasPermissionSimulatedByPermissionId(id: number) {
  const permissionIdSimulated = IDS_FOR_PERMISSION_SIMULATED[id];

  const hasPermissionIdSimulated = permissionIdSimulated?.some((permissionName: string) => {
    const permission = foundPermission(permissionName);

    if (!permission) return false;

    return validatePermissionDate(permission);
  });

  return hasPermissionIdSimulated;
}

export { hasPermissionSimulated, hasPermissionSimulatedByPermissionId };
