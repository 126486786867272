
























import { Component, Vue, Prop } from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import VideoThumb from '@/components/VideoThumb/index.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import ExSlick from '@/components/Slick/index.vue';
import ListVideosLoading from '@/components/ListVideosLoading/index.vue';

import HistoricService from '@/services/Historic/historicService';

import { VideosHistoric } from '@/globalInterfaces/Video';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

@Component({
  components: {
    ExSlick,
    VideoThumb,
    BoxContainer,
    ContainerFluid,
    FeedbackUser,
    ListVideosLoading
  }
})
export default class ListVideos extends Vue {
  @Prop({ default: '' }) title!: string;

  private isLoading = true;
  private videoList: Array<VideosHistoric> = [];

  private historicService = new HistoricService();

  async mounted() {
    this.getMyListVideos();
  }

  get plan() {
    return this.$store.getters.profile?.plan;
  }

  get isPlanFree() {
    return this.plan?.planID === LIST_PLAN_ID.FREE;
  }

  async getMyListVideos() {
    try {
      this.isLoading = true;

      const videoList = await this.historicService.getMyListVideoFavorite({
        isFree: this.isPlanFree
      });

      if (videoList?.videos.length) {
        const filteredVideos = this.filterExistingVideos(videoList.videos);

        this.videoList = filteredVideos;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar minha lista de vídeos favoritos.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  filterExistingVideos(videos: Array<VideosHistoric>) {
    return videos.filter((video: VideosHistoric) => video.object);
  }

  async setRemoveVideoList(video: Record<string, any>) {
    this.videoList.forEach((item, index) => {
      if (item.idobject === video.idobject) {
        this.videoList.splice(index, 1);
      }
    });

    await this.historicService.saveHistoric({
      idobject: Number(video.idobject),
      type: 'video',
      list: false
    });
  }

  redirectToVideo(videoData: VideosHistoric) {
    if (!videoData) return;

    const {
      context, path, idTopic, slug 
    } = videoData.object;

    this.$router.push({
      name: 'videos',
      params: {
        context,
        path,
        topicID: idTopic,
        fileID: String(videoData.idobject),
        slugFile: slug
      }
    });
  }
}
