import { compareAsc, parseISO, sub } from 'date-fns';
import store from '@/store';

import DoubtsService from '@/services/Doubts/DoubtsService';
import RedactionService from '@/services/Redaction/RedactionService';

import { CountDoubtsMonth } from '@/globalInterfaces/Doubts';

interface PermissionInterface {
  ID: number;
  end_date: string | Date;
  name: string;
  start_date: string | Date;
}

const DOUBTS_PERMISSION_WITH_QUANTITY = 'doubts';
const REDACTION_PERMISSION_WITH_QUANTITY = 'redaction';
const UNLIMITED = 'unlimited';
const QUANTITY_UNLIMITED_REDACTION = 99999;
const VALID_FINAL_DATE = 1;
const DATES_EQUAL = 0;

const LIST_PERMISSION_WITH_QUANTITY = [
  DOUBTS_PERMISSION_WITH_QUANTITY,
  REDACTION_PERMISSION_WITH_QUANTITY
];

export default class Permission {
  private DoubtsService = new DoubtsService();
  protected RedactionService = new RedactionService();

  permissionsStore() {
    return store.getters.profile?.permissions;
  }

  can(permission: string) {
    const permissions = this.getListPermission(permission) ?? [];

    return !!permissions.length;
  }

  findPermission(permissionUser: string) {
    const userPermissions = this.permissionsStore();

    return userPermissions.find((permission: PermissionInterface) => permission.name.includes(permissionUser));
  }

  validatePermissionDate(permission: PermissionInterface) {
    const end_date = parseISO(String(permission.end_date));
    const currentDate = sub(new Date(), { hours: 3 });

    const expiredDate = compareAsc(end_date, currentDate);

    return expiredDate === VALID_FINAL_DATE || expiredDate === DATES_EQUAL;
  }

  async can_credits(permission: string) {
    if (!this.can(permission)) return false;

    const result = await this.validateQuantity(permission);

    return result;
  }

  async validateQuantity(permissionUser: string) {
    if (!LIST_PERMISSION_WITH_QUANTITY.includes(permissionUser)) return true;

    const quantityTotalPermissionSend = this.getQuantityPermission(permissionUser) ?? 0;

    if (permissionUser === DOUBTS_PERMISSION_WITH_QUANTITY) {
      const quantityDoubts = await this.getQuantityDoubts();

      if (!quantityDoubts && quantityDoubts !== 0) return false;

      return quantityTotalPermissionSend - quantityDoubts > 0;
    }

    const quantityRedaction = await this.getTotalRedactionSend();

    return quantityTotalPermissionSend - quantityRedaction > 0;
  }

  getQuantityPermission(permission: string) {
    if (!this.can(permission)) return null;
    const permissionsFound = this.getListPermission(permission);

    const hasPermissionUnlimited = this.hasPermissionUnlimited(permissionsFound);

    if (hasPermissionUnlimited) return QUANTITY_UNLIMITED_REDACTION;

    return this.totalPermission(permissionsFound);
  }

  getListPermission(permissionUser: string) {
    const userPermissions = this.permissionsStore();

    return userPermissions.filter(
      (permission: PermissionInterface) => permission.name.includes(permissionUser) && this.validatePermissionDate(permission)
    );
  }

  hasPermissionUnlimited(permissions: Array<PermissionInterface>) {
    return permissions.some((permission) => {
      const splitPermission = permission.name.split('_');

      return splitPermission[1] && splitPermission[1] === UNLIMITED;
    });
  }

  totalPermission(permissions: Array<PermissionInterface>) {
    return permissions.reduce((accumulator, permission) => {
      const splitPermission = permission.name.split('_');
      const isNotNumber = Number.isNaN(Number(splitPermission[1]));

      if (!splitPermission[1] || isNotNumber) return accumulator;

      return accumulator + Number(splitPermission[1]);
    }, 0);
  }

  async getQuantityDoubts() {
    try {
      const response: CountDoubtsMonth = await this.DoubtsService.getQuantityDoubts();

      if (response && response?.count >= 0) {
        this.setStoreQuantityDoubtsMonthly(response?.count);

        return response?.count;
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getTotalRedactionSend() {
    try {
      const response = await this.RedactionService.getTotalRedactionSend();

      if (response?.total_redactions_me_monthly) {
        this.setStoreTotalRedactionSend(response?.total_redactions_me_monthly);
        return response?.total_redactions_me_monthly;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  setStoreTotalRedactionSend(quantity: number) {
    store.commit('setTotalRedactionsMeMonthly', quantity);
  }

  setStoreQuantityDoubtsMonthly(quantity: number) {
    store.commit('setTotalDoubtsMonthly', quantity || 0);
  }
}
