import store from '@/store';
import { THEME, LIGHT_MODE_THEME, DARK_MODE_THEME } from '@/share/Util/Color/PlatformColors';

function checkBackgroundColor() {
  let color = '';
  const isB2b = store.getters.profile.institution?.name;

  if (isB2b) {
    color = THEME.theme_light['neutralcolor-high-light'];
  } else {
    const currentThemeStorage = localStorage.getItem('ex-theme');

    if (!currentThemeStorage) {
      color = THEME.theme_light['neutralcolor-high-light'];
    } else {
      color = currentThemeStorage === LIGHT_MODE_THEME
        ? THEME.theme_light['neutralcolor-high-light']
        : THEME.theme_dark['neutralcolor-high-dark'];
    }
  }

  return color;
}

function setBackgroundColor() {
  const backgroundColor = checkBackgroundColor();
  const app = document.getElementById('app');

  if (app) {
    app.setAttribute('style', `background-color: ${backgroundColor}`);
  }
}

export function configureColors(theme: Record<string, any>) {
  document.documentElement.style.setProperty('--primarycolor-pure', theme['primarycolor-pure']);

  document.documentElement.style.setProperty('--primarycolor-dark', theme['primarycolor-dark']);

  document.documentElement.style.setProperty('--primarycolor-medium', theme['primarycolor-medium']);

  document.documentElement.style.setProperty('--primarycolor-light', theme['primarycolor-light']);

  document.documentElement.style.setProperty(
    '--primarycolor-lightest',
    theme['primarycolor-lightest']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-low-pure',
    theme['neutralcolor-low-pure']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-low-dark',
    theme['neutralcolor-low-dark']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-low-medium',
    theme['neutralcolor-low-medium']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-low-light',
    theme['neutralcolor-low-light']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-high-pure',
    theme['neutralcolor-high-pure']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-high-dark',
    theme['neutralcolor-high-dark']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-high-medium',
    theme['neutralcolor-high-medium']
  );

  document.documentElement.style.setProperty(
    '--neutralcolor-high-light',
    theme['neutralcolor-high-light']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-success-pure',
    theme['feedbackcolor-success-pure']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-success-dark',
    theme['feedbackcolor-success-dark']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-success-medium',
    theme['feedbackcolor-success-medium']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-success-light',
    theme['feedbackcolor-success-light']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-info-pure',
    theme['feedbackcolor-info-pure']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-info-dark',
    theme['feedbackcolor-info-dark']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-info-medium',
    theme['feedbackcolor-info-medium']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-info-light',
    theme['feedbackcolor-info-light']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-warning-pure',
    theme['feedbackcolor-warning-pure']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-warning-dark',
    theme['feedbackcolor-warning-dark']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-warning-medium',
    theme['feedbackcolor-warning-medium']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-warning-light',
    theme['feedbackcolor-warning-light']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-error-pure',
    theme['feedbackcolor-error-pure']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-error-dark',
    theme['feedbackcolor-error-dark']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-error-medium',
    theme['feedbackcolor-error-medium']
  );

  document.documentElement.style.setProperty(
    '--feedbackcolor-error-light',
    theme['feedbackcolor-error-light']
  );

  setBackgroundColor();
}

function configureInstitutionColors(theme: Record<string, any>) {
  const listColors = Object.values(theme);
  const haveAllColors = listColors.every((color) => color);

  if (haveAllColors) {
    THEME.theme_light['primarycolor-pure'] = theme['primarycolor-pure'];
    THEME.theme_light['primarycolor-dark'] = theme['primarycolor-dark'];
    THEME.theme_light['primarycolor-medium'] = theme['primarycolor-medium'];
    THEME.theme_light['primarycolor-light'] = theme['primarycolor-light'];
    THEME.theme_light['primarycolor-lightest'] = theme['primarycolor-lightest'];
  }

  configureColors(THEME.theme_light);
}

function configurePlatformTheme() {
  const currentTheme = localStorage.getItem('ex-theme');

  if (!currentTheme) {
    configureColors(THEME.theme_light);
    localStorage.setItem('ex-theme', LIGHT_MODE_THEME);
    localStorage.setItem('ex-theme-initial', LIGHT_MODE_THEME);
    return;
  }

  const isDarkMode = currentTheme === DARK_MODE_THEME;

  configureColors(THEME[currentTheme]);
  store.commit('setIsDarkMode', isDarkMode);
}

export function assessBusinessModelForTheme(profile?: Record<string, any>) {
  const isB2b = profile?.institution?.name;

  if (isB2b) {
    const theme = profile?.theme[LIGHT_MODE_THEME];
    configureInstitutionColors(theme);
  } else {
    configurePlatformTheme();
  }
}
