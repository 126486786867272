























import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import Checkbox from '@/components/Input/Checkbox/index.vue';

const DEFAULT_MESSAGE = 'Olá, gostaria de realizar uma mudança de plano.';

@Component({
  components: {
    Modal,
    Checkbox
  }
})
export default class ModalNotContentPlan extends Vue {
  goToRefreshPlan() {
    const text = DEFAULT_MESSAGE;

    return `https://api.whatsapp.com/send?phone=557981093809&text=${text}`;
  }
}
