const SplashScreen = {
  namespaced: true,
  state: {
    isSplashScreen: true
  },
  mutations: {
    setIsSplashScreen(state: any, payload: boolean) {
      state.isSplashScreen = payload;
    }
  },
  getters: {
    getIsSplashScreen: (state: any) => state.isSplashScreen
  }
};

export default SplashScreen;
