/* eslint-disable import/no-cycle */
import store from '@/store';

import AppClient from '../AppClient';

import { getMaterialCategories, sortContentBook } from './scripts/SortContents';

import { searchStoreFiles } from '../Util/searchStoreFiles';

import { Book } from '@/globalInterfaces/Book';

interface VideosQuestions {
  videoID?: number[];
  questionID?: number[];
}

export default class BookService {
  private basePath = process.env.VUE_APP_BOOK_API;

  private Client = new AppClient({ url: this.basePath });

  async books(degreeID?: number[], institutionID?: number) {
    const URI = '/books';

    const { allBooks } = store.getters;

    if (allBooks && allBooks?.length && degreeID !== undefined) {
      const filteredBooks: Book[] = allBooks.filter((book: Book) => degreeID?.includes(book?.degreeID));

      if (filteredBooks && filteredBooks.length) return filteredBooks;
    }

    const response = await this.Client.get(URI, { degreeID, institutionID });

    store.commit('setAllBooks', response?.data);

    return response?.data;
  }

  async getTeachers(idBook: number, slugBook: string) {
    const URI = `/teachers/book/${idBook}`;

    const { teachersBook, bookPath } = store.getters;

    if (bookPath && bookPath.toUpperCase() === slugBook.toUpperCase() && teachersBook.length) {
      return teachersBook;
    }

    const response = await this.Client.get(URI);

    store.commit('setBookPath', slugBook);
    store.commit('setTeachersBook', response?.data);

    return response?.data;
  }

  async book(slug: string) {
    const URI = `/book/${slug}`;

    const { book } = store.getters;

    if (book?.slug && book?.slug.toUpperCase() === slug.toUpperCase()) {
      store.commit('setIsLoading', false);
      return book;
    }

    const response = await this.Client.get(URI);

    await getMaterialCategories();

    const bookContentsSorted = sortContentBook(response?.data.books[0]);

    store.commit('setBook', bookContentsSorted);
    store.commit('setIsLoading', false);

    return bookContentsSorted;
  }

  async getTopicsBySlugBook(slugBook: string) {
    const URI = `/book/slug/${slugBook}/topics`;

    const { slug, topics } = store.getters.topicsBySlugBook;

    if (slug === slugBook && topics.length) {
      return topics;
    }

    const response = await this.Client.get(URI);

    store.commit('setTopicsBySlugBook', {
      slug: slugBook,
      topics: response?.data?.topics
    });
    store.commit('setBookPath', slugBook);
    store.commit('setTeachersBook', []);

    return response?.data?.topics;
  }

  async getBookTopic(idBook: number, idTopic: number) {
    const URI = `/book/${idBook}/topic/${idTopic}`;

    if (store.getters.topic?.idTopic === idTopic) {
      return store.getters.topic;
    }

    const response = await this.Client.get(URI);

    await getMaterialCategories();

    const topicContentsSorted = sortContentBook(response?.data?.topic);

    store.commit('setTopic', topicContentsSorted);

    return topicContentsSorted;
  }

  async getTopicsBook(idBook: number, slugBook?: string) {
    const URI = `/book/${idBook}/topics`;

    const { bookPath } = store.getters;
    const { bookTopic } = store.getters;

    if (slugBook && bookPath === slugBook && bookTopic.length) {
      return bookTopic;
    }

    const response = await this.Client.get(URI);

    store.commit('setBookTopic', response?.data?.topics);
    store.commit('setBookPath', slugBook);
    store.commit('setTeachersBook', []);

    return response?.data?.topics;
  }

  async quotes() {
    const URI = '/quote';

    const response = await this.Client.get(URI);

    return response?.data;
  }

  async getAnnotations(type: string, fileId: string) {
    const URI = '/annotations';

    const response = await this.Client.get(URI, { type, fileId });

    return response?.data;
  }

  async postAnnotations(type: string, fileId: string, content: string, order: number) {
    const URI = '/annotations';

    const response = await this.Client.post(URI, {
      type,
      fileId,
      content,
      order
    });

    return response?.data;
  }

  async getSearch(text: string, idBook?: number, limit = 10) {
    let URI = '/search';

    if (idBook) URI = `${URI}/book/${idBook}`;

    const response = await this.Client.get(URI, { text, limit });

    return response?.data;
  }

  async getComments(context: string, fileId: string) {
    const URI = '/comments';

    const response = await this.Client.get(URI, { context, fileId });

    return response?.data;
  }

  async postComments(
    context: string,
    bookId: string,
    topicId: string,
    content: string,
    fileId: string
  ) {
    const URI = '/comments';

    const response = await this.Client.post(URI, {
      context,
      fileId,
      content,
      bookId,
      topicId
    });

    return response?.data;
  }

  async getArchives(ids: Array<number>) {
    const filterFiles = searchStoreFiles(ids, 'filesArchives', 'idArchive');

    if (!filterFiles?.idsFiles.length) return filterFiles.files;

    const URI = '/archives';

    const response = await this.Client.get(URI, {
      idArchives: filterFiles.idsFiles
    });

    if (response?.data) {
      const files = [...filterFiles.files, ...response?.data];

      store.commit('setFilesVideos', files);

      return files;
    }

    return response?.data;
  }

  async saveViewVideoCredits(videoId: number) {
    const URI = '/videos/credits/view';

    await this.Client.post(URI, { videoId });
  }

  async getDegrees() {
    const { degrees } = store.getters;

    if (degrees.length) return degrees;

    const URI = '/degree';

    const response = await this.Client.get(URI);

    store.commit('setDegrees', response?.data?.degrees);

    return response?.data?.degrees;
  }

  async getRelationshipDiscipline(disciplineID: number[]) {
    const URI = '/disciplines';

    const response = await this.Client.get(URI, { disciplineID });

    return response?.data?.disciplines;
  }

  async getVideosQuestions(data: VideosQuestions) {
    const URI = '/videos/questions';

    const response = await this.Client.get(URI, {
      videoID: data?.videoID,
      questionID: data?.questionID
    });

    return response?.data?.videosQuestions[0];
  }

  async getVideosRedirect(redirect: string) {
    const URI = '/videos/redirect';

    const response = await this.Client.get(URI, {
      redirect
    });

    return response?.data;
  }
}
