const Redaction = {
  state: {
    redactionSubmissionSettings: {
      component: '',
      time: null
    },
    currentTimeRedaction: null,
    canOpenProposals: true,
    themesOfSimulated: []
  },
  mutations: {
    setRedactionSubmissionSettings(state: Record<string, any>, payload: Record<string, any>) {
      state.redactionSubmissionSettings = payload;
      sessionStorage.setItem('componentSendRedaction', payload.component);
      sessionStorage.setItem('timeSendRedaction', payload.time);
    },
    setCurrentTimeRedaction(state: Record<string, any>, payload: number) {
      state.currentTimeRedaction = payload;
    },
    setCanOpenProposals(state: Record<string, any>, payload: boolean) {
      state.canOpenProposals = payload;
    },
    setThemesOfSimulated(state: Record<string, any>, payload: Array<Record<string, any>>) {
      state.themesOfSimulated = payload;
    }
  },
  getters: {
    redactionSubmissionSettings: (state: Record<string, any>) => state.redactionSubmissionSettings,
    currentTimeRedaction: (state: Record<string, any>) => state.currentTimeRedaction,
    canOpenProposals: (state: Record<string, any>) => state.canOpenProposals,
    themesOfSimulated: (state: Record<string, any>) => state.themesOfSimulated
  }
};

export default Redaction;
