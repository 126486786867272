













































import {
  Component, Mixins, Prop, Watch 
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ContainerVideos from '@/components/ContainerVideos/ContainerVideos.vue';
import Ranking from '@/components/Ranking/index.vue';

import ListBooks from '../ListBooks/index.vue';
import ContainerRedation from '../ContainerRedaction/ContainerRedaction.vue';

import { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';

import Search from '@/widgets/Util/Search/index.vue';
import Paragraph from '@/widgets/Util/Paragraph/Paragraph.vue';
// import ListNews from '@/widgets/News/ListNews/ListNews.vue';
import SaleOfProducts from '@/widgets/Products/SaleOfProducts/SaleOfProducts.vue';
import RealtimeUsers from '@/widgets/Users/RealtimeUsers/RealtimeUsers.vue';

import searchBook from '@/mixins/searchBook';
import SetSaleOfProducts from '@/mixins/SetSaleOfProduts';

import { NewCourse } from '@/globalInterfaces/Course';
import { ProductData } from '@/globalInterfaces/ProductCard';
import { Book as IBook } from '@/globalInterfaces/Book';

import { TAB_MENU, TAB_MENU_SALES, SLUGS } from '../../Constants';

interface ListBook {
  id: string;
  books: Array<Record<string, any>>;
}

const SLUG_BOOK_REDACTION = 'redacao';

@Component({
  components: {
    BoxContainer,
    ListBooks,
    Search,
    ContainerRedation,
    ContainerVideos,
    SaleOfProducts,
    Paragraph,
    RealtimeUsers,
    // ListNews,
    Ranking
  }
})
export default class RedactionDashboard extends Mixins(searchBook, SetSaleOfProducts) {
  @Prop() listOfAllowedCourses!: Array<NewCourse>;
  @Prop() hasPermissionCourses!: boolean;
  @Prop() isHighSchool!: boolean;

  private isLoadingBooks = false;

  private listAllTabBooks: Array<ListBook> = [];
  private tabMenuSales: Array<TabsMenuInterface> = [];
  private tabMenuBooks: Array<TabsMenuInterface> = [];
  private currentTabBookList: ListBook | Record<string, any> = {};

  async created() {
    this.setBook();
    this.setCoursesForSale();
  }

  mounted() {
    this.setBookTabs();
  }

  async setBook() {
    try {
      this.isLoadingBooks = true;
      const books = await this.BookService.books(
        undefined,
        this.$store.getters.profile?.institution?.id
      );
      const bookRedaction = this.findBookRedaction(books);

      if (bookRedaction) {
        const newBook = {
          ...bookRedaction,
          id: bookRedaction.idBooks,
          colorBook: bookRedaction.color,
          cover: bookRedaction.thumb,
          link: `/caderno/${bookRedaction.slug}`,
          type: 'books',
          disable: false
        };

        const objectBookRedaction = {
          id: SLUGS.REDACTION,
          books: [newBook]
        };

        this.listAllTabBooks.push(objectBookRedaction);
        this.currentTabBookList = objectBookRedaction;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os livros.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoadingBooks = false;
    }
  }

  findBookRedaction(books: Array<IBook>) {
    return books.find((book: IBook) => book.slug === SLUG_BOOK_REDACTION);
  }

  setCoursesForSale() {
    this.filterCoursesForSaleWithoutAccess(this.listOfAllowedCourses);
    this.setTabSales();
  }

  setTabSales() {
    if (this.listAllProductsForSale.length) {
      this.tabMenuSales = this.filterTabSales();
      this.tabMenuSales[0].active = true;
      return;
    }

    this.tabMenuSales = TAB_MENU_SALES;
  }

  filterTabSales() {
    const NOT_FOUND_INDEX = -1;
    return TAB_MENU_SALES.filter(
      (tab: TabsMenuInterface) => this.listAllProductsForSale.findIndex((product: ProductData) => product.id === tab.slug)
        !== NOT_FOUND_INDEX
    );
  }

  @Watch('hasPermissionCourses')
  setBookTabs() {
    this.tabMenuBooks = this.filterDefaultBookTabs();

    if (!this.hasPermissionCourses && !this.isHighSchool) this.removeElementInBookTabs(SLUGS.COURSES);

    this.setHoverOnFirstTab();
  }

  filterDefaultBookTabs() {
    return TAB_MENU.filter(
      (tab: TabsMenuInterface) => tab.slug === SLUGS.REDACTION || tab.slug === SLUGS.COURSES
    );
  }

  removeElementInBookTabs(elementSlug: string) {
    this.tabMenuBooks = this.tabMenuBooks.filter(
      (tab: TabsMenuInterface) => tab.slug !== elementSlug
    );
  }

  setHoverOnFirstTab() {
    if (this.tabMenuBooks.length >= 2) {
      this.tabMenuBooks[0].active = true;
    }
  }

  @Watch('listOfAllowedCourses', {
    immediate: true
  })
  setCourseBookTab() {
    if (this.listOfAllowedCourses.length) {
      const objectBookCourses = {
        id: 'courses',
        books: this.listOfAllowedCourses
      };

      this.listAllTabBooks.push(objectBookCourses);
    }
  }

  changeCurrentListBook(idBook: string) {
    if (idBook === this.currentTabBookList?.id) return;

    const selectedTabBook = this.listAllTabBooks.find((book: ListBook) => book.id === idBook);
    this.currentTabBookList = selectedTabBook || this.currentTabBookList;
  }
}
