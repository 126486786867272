import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_DEFAULT = {
  START: 'begin'
};

export const Default: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_DEFAULT.START,
        redirect: '/dashboard'
      }
    ]
  }
];
