


































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import ExSlick from '@/components/Slick/index.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';

@Component({
  components: {
    BoxContainer,
    ExLoading,
    ExSlick
  }
})
export default class ListVideosLoading extends Vue {
  private quantityLoading = 4;
}
