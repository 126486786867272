










import { Component, Vue, Prop } from 'vue-property-decorator';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import ActiveModal from '@/share/Util/ActiveModal';

const LINK_SHEET_REDACTION = 'https://explicae.com.br/app/explicae-folhaderedacao.pdf';

@Component({})
export default class PrintRedactionSheet extends Vue {
  @Prop({ default: true }) isPermission!: boolean;

  private setModal = new ActiveModal();

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  downloadSheet() {
    if (!this.isPermission) {
      this.setShowModal();

      return;
    }

    this.setTrackAmplitude();

    window.open(LINK_SHEET_REDACTION, '_blank');
  }

  setShowModal() {
    if (this.isPlanFree) this.showModal('modalPlanUpdate');
    else this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.setModal.activeModal(id);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'PrintRedactionSheet',
          local: this.$route.name
        }
      }
    });
  }
}
