



































































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';
import ExLoading from '@/globalComponents/ExLoading/index.vue';
import Select from '@/components/Input/Select/Select.vue';
// import CustomerService from '@/components/CustomerService/index.vue';
import UploadImageSection from './components/UploadImageSection/index.vue';

import ProblemService from '@/services/Problem/ProblemService';

import ModalReportProblemFeedback from './ModalReportProblemFeedback.vue';

import { Ticket } from '@/globalInterfaces/Ticket';
import { Features, ListFeatures } from './interface';

import ActiveModal from '@/share/Util/ActiveModal';
import { removeEmptyValues } from '@/share/Util/UsefulFunctions/UserfulFunctions';

@Component({
  components: {
    Modal,
    Select,
    // CustomerService,
    ModalReportProblemFeedback,
    ExLoading,
    UploadImageSection
  }
})
export default class ModalReportProblem extends Vue {
  private selectedFeatureID: number | null = null;

  private textArea = '';
  private image = '';

  private isSuccessful = true;
  private isLoadingFeatures = true;

  private listFeatures: ListFeatures[] = [];

  private ProblemService = new ProblemService();
  private modalInstance = new ActiveModal();

  created() {
    this.getFeatures();
  }

  beforeDestroy() {
    this.clearForm();
  }

  get validation() {
    return this.selectedFeatureID !== null;
  }

  async getFeatures() {
    try {
      this.isLoadingFeatures = true;

      const features = await this.ProblemService.getFeatures();

      this.listFeatures = this.setFeatures(features);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingFeatures = false;
    }
  }

  setFeatures(features: Features[]) {
    if (!features || !features.length) return [];

    return features.map((feature: Features) => ({ ...feature, value: feature.id }));
  }

  async sendReportProblem() {
    try {
      const ticket = this.getTicketData();

      if (!ticket) return;

      await this.ProblemService.sendTicket(ticket);

      this.clearForm();
      this.isSuccessful = true;
    } catch (error) {
      this.isSuccessful = false;
      console.error(error);
    } finally {
      this.setModalFeedback();
    }
  }

  getTicketData() {
    if (!this.selectedFeatureID) return null;

    let ticket: Ticket = {
      ticketFeaturesID: this.selectedFeatureID,
      routeUrl: window.location.href
    };

    if (this.image) {
      ticket = {
        ...ticket,
        image: this.image
      };
    }

    if (this.textArea) {
      ticket = {
        ...ticket,
        description: this.textArea
      };
    }

    return ticket;
  }

  clearForm() {
    this.selectedFeatureID = null;
    this.textArea = '';
  }

  setModalFeedback() {
    this.modalInstance.activeModal('ModalReportProblemFeedback');
  }

  getSelectedFeature(featureID: number) {
    this.selectedFeatureID = featureID;
  }

  getImage(image: string) {
    this.image = image;
  }
}
