
































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';
import store from '@/store';

import Arrow from '@/assets/icons/Arrow.vue';

const DEFAULT_OPTION_TEXT = 'Selecione...';
const COLOR_FILL_ARROW = 'var(--neutralcolor-low-pure)';

@Component({
  components: {
    Arrow
  }
})
export default class Select extends Vue {
  @Prop({ default: DEFAULT_OPTION_TEXT }) optionDefault!: string;
  @Prop() title?: string;
  @Prop() selectList!: Array<Record<string, any>>;
  @Prop() width!: string;
  @Prop() margin!: string;
  @Prop() name!: string;
  @Prop({ default: '' }) selectedOptionProp!: string;
  @Prop() setValue!: Function;
  @Prop() isDisabled!: boolean;
  @Prop({ default: false }) showOptionAll!: boolean;

  private COLOR_FILL_ARROW = COLOR_FILL_ARROW;

  private selectedOption: string | number = this.selectedOptionProp;

  get isDarkMode() {
    return store.getters.isDarkMode;
  }

  @Watch('selectedOptionProp')
  getOptionProp() {
    this.selectedOption = this.selectedOptionProp;
  }

  @Watch('selectedOption')
  setSelectChange() {
    if (this.setValue) {
      this.setValue(this.selectedOption);
    }

    this.$emit('change-select', this.selectedOption);
  }
}
