import { RouteConfig } from 'vue-router';

export const NAME_ROUTES_CONQUISTAE = {
  LAUNCH: 'Launch',
  WELCOME: 'Welcome',
  CONFIG: 'Config',
  LEVELING: 'Leveling',
  LEVELING_RESULT: 'Leveling-result',
  WAR_ROOM: 'War-room',
  TRAINING_CENTER: 'Training-center',
  MISSION_LIST: 'Mission-List'
};

export const War: Array<RouteConfig> = [
  {
    path: '/conquistae',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_CONQUISTAE.LAUNCH,
        component: () => import(/* webpackChunkName: "conquistae" */ '../../pages/War/pages/Launch/Launch.vue'),
        meta: { title: 'Conquistaê' }
      }
    ]
  },
  {
    path: '/conquistae',
    component: () => import('@/layouts/War/Welcome/index.vue'),
    children: [
      {
        path: 'welcome',
        name: NAME_ROUTES_CONQUISTAE.WELCOME,
        component: () => import(
          /* webpackChunkName: "conquistae-welcome" */ '../../pages/War/pages/Welcome/Welcome.vue'
        ),
        meta: { title: 'Welcome - conquistaê' }
      }
    ]
  },
  {
    path: '/conquistae',
    component: () => import('@/layouts/War/Default/index.vue'),
    children: [
      {
        path: 'configuracao',
        name: NAME_ROUTES_CONQUISTAE.CONFIG,
        component: () => import(
          /* webpackChunkName: "conquistae-config" */ '../../pages/War/pages/Config/Config.vue'
        ),
        meta: { title: 'Configuração - conquistaê' }
      },
      {
        path: 'nivelamento',
        name: NAME_ROUTES_CONQUISTAE.LEVELING,
        component: () => import(
          /* webpackChunkName: "conquistae-leveling" */ '../../pages/War/pages/Leveling/Leveling.vue'
        ),
        meta: { title: 'Nivelamento - conquistaê' }
      },
      {
        path: 'nivelamento/resultado',
        name: NAME_ROUTES_CONQUISTAE.LEVELING_RESULT,
        component: () => import(
          /* webpackChunkName: "conquistae-leveling-result" */ '../../pages/War/pages/LevelingResult/LevelingResult.vue'
        ),
        meta: { title: 'Nivelamento - conquistaê' }
      },
      {
        path: 'lista-de-missao',
        name: NAME_ROUTES_CONQUISTAE.MISSION_LIST,
        component: () => import(/* webpackChunkName: "conquistae-training-center" */ '../../pages/War/pages/Mission/MissionList.vue'),
        meta: { title: 'Lista de Missão - conquistaê' } 
      },
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "conquistae-headquarters" */ '../../pages/War/pages/Headquarters/Headquarters.vue'
        ),
        children: [
          {
            path: 'quartel-de-operacoes',
            name: NAME_ROUTES_CONQUISTAE.WAR_ROOM,
            component: () => import(
              /* webpackChunkName: "conquistae-war-room" */ '../../pages/War/pages/WarRoom/WarRoom.vue'
            ),
            meta: { title: 'Quartel de Operações - conquistaê' }
          },
          {
            path: 'centro-de-treinamento',
            name: NAME_ROUTES_CONQUISTAE.TRAINING_CENTER,
            component: () => import(
              /* webpackChunkName: "conquistae-training-center" */ '../../pages/War/pages/TrainingCenter/TrainingCenter.vue'
            ),
            meta: { title: 'Centro de Treinamento - conquistaê' }
          }
        ]
      }
    ]
  }
];
