import { DEFAULT_COPY_MODAL_PLAN_UPDATE } from '@/constant/Modal';

interface ModalData {
  title: string;
  description: string;
}

const Modal = {
  state: {
    modalPlanUpdate: DEFAULT_COPY_MODAL_PLAN_UPDATE
  },
  mutations: {
    setModalPlanUpdate(state: Record<string, any>, payload: ModalData) {
      state.modalPlanUpdate = payload;
    }
  },
  getters: {
    modalPlanUpdate: (state: Record<string, any>) => state.modalPlanUpdate
  }
};

export default Modal;
