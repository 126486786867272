export const SIMULATED_COVERS: Record<number, string> = {
  9: 'vestibulares-anteriores',
  294: 'simulados-de-revisao',
  293: 'simulados-dos-cursos',
  192: 'simulados-exp-free'
};

export const SIMULATED_IDS = {
  anteriores: 9,
  revisao: 294,
  cursos: 293,
  'exp-free': 192
};
