















































import {
  Component, Prop, Vue, Watch 
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import iSearch from '@/assets/icons/Search.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

@Component({
  components: {
    BoxContainer,
    iSearch
  }
})
export default class Search extends Vue {
  @Prop() color!: string;
  @Prop() backgroundProfile!: string;
  @Prop({ default: () => [] }) searchList!: Array<Record<string, any>>;
  @Prop({ default: '' }) searchNotFound!: string;
  @Prop({ required: false }) idBook!: number;

  private inputSearch = '';
  private timeout: any = null;
  private isResearching = false;

  mounted() {
    this.searchPosition();
  }

  beforeDestroy() {
    clearTimeout(this.timeout);
  }

  @Watch('inputSearch')
  searchContent() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.$emit('text-search', this.inputSearch, this.idBook);
    }, 500);
  }

  searchLink(item: Record<string, any>) {
    this.$emit('setlink-search', item);
  }

  startAnimation() {
    this.inputSearch = '';
    this.isResearching = !this.isResearching;
    const input: any = this.$refs.search;
    input.focus();

    this.setTrackAmplitude();
  }

  finishAnimation() {
    if (this.isResearching) {
      this.inputSearch = '';
      this.isResearching = !this.isResearching;
    }
  }

  @Watch('$responsive.contentwidth')
  @Watch('isResearching')
  searchPosition() {
    // @ts-ignore
    const boxSearch: HTMLBodyElement = document.getElementsByClassName('search__box app-box')[0];
    const { searchList }: any = this.$refs;
    searchList.setAttribute('style', `width: ${boxSearch.offsetWidth}px`);
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Search',
          local: 'Pesquisa'
        }
      }
    });
  }
}
