import { RouteConfig } from 'vue-router';

import { multiGuard } from '../Middleware/MultiGuard';

import { permissionFeature } from '../Middleware/permission/Feature';
import { permissionSimulated, permissionSimulator } from '../Middleware/permission/Simulated';

import { onboardingSimulated } from '../Middleware/onboarding';

import MiddlewareSimulated from '../Middleware/CheckUserStatusInSimulated';

export const NAME_ROUTES_SIMULATION = {
  LIST: 'SimulationsList',
  TUTORIAL: 'TutorialSimulation',
  INSTRUCTION: 'SimulatedInstruction',
  OTHER: 'OtherSimulated',
  QUESTIONS: 'SimulatedQuestions',
  QUESTIONS_RESULT: 'SimulatedResult',
  SIMULATOR: 'EnemSimulator',
  SIMULATOR_RESULT: 'SimulatorResult'
};

export const Simulation: Array<RouteConfig> = [
  {
    path: '/simulados',
    component: () => import('@/layouts/Default/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_SIMULATION.LIST,
        component: () => import(
          /* webpackChunkName: "simulated-list" */ '@/pages/Simulated/pages/ListSimulations/ListSimulations.vue'
        ),
        meta: { title: 'Simulados' },
        beforeEnter: multiGuard([permissionFeature, onboardingSimulated])
      },
      {
        path: 'tutorial',
        name: NAME_ROUTES_SIMULATION.TUTORIAL,
        component: () => import(
          /* webpackChunkName: "simulated-tutorial" */ '@/pages/Simulated/pages/TutorialSimulated/index.vue'
        ),
        meta: { title: 'Tutorial Simulado' },
        beforeEnter: permissionFeature
      }
    ]
  },
  {
    path: '/simulado/:id?',
    component: () => import('@/layouts/Sidebar/index.vue'),
    children: [
      {
        path: '',
        name: NAME_ROUTES_SIMULATION.INSTRUCTION,
        component: () => import(/* webpackChunkName: "simulated-instruction" */ '@/pages/Simulated/Simulated.vue'),
        meta: { title: 'Simulado' },
        beforeEnter: multiGuard([
          permissionFeature,
          // permissionSimulated,
          MiddlewareSimulated.beforeEnterInstruction
        ])
      },
      {
        path: 'lista/:url',
        name: NAME_ROUTES_SIMULATION.OTHER,
        component: () => import(
          /* webpackChunkName: "simulated" */ '@/pages/Simulated/pages/OtherSimulated/OtherSimulated.vue'
        ),
        meta: { title: 'Vestibulares Anteriores' },
        beforeEnter: multiGuard([
          permissionFeature
          // permissionSimulated,
        ])
      },
      {
        path: 'questions',
        name: NAME_ROUTES_SIMULATION.QUESTIONS,
        component: () => import(
          /* webpackChunkName: "simulated-question" */ '@/pages/Simulated/pages/SimulatedQuestion/SimulatedQuestion.vue'
        ),
        meta: { title: 'Simulado Questões' },
        beforeEnter: multiGuard([
          permissionFeature,
          permissionSimulated,
          MiddlewareSimulated.beforeEnterQuestions
        ])
      },
      {
        path: 'resultado',
        name: NAME_ROUTES_SIMULATION.QUESTIONS_RESULT,
        component: () => import(
          /* webpackChunkName: "simulated-result" */ '@/pages/Simulated/pages/SimulatedResult/SimulatedResult.vue'
        ),
        meta: { title: 'Resultado do Simulado' },
        beforeEnter: multiGuard([
          permissionFeature,
          permissionSimulated,
          MiddlewareSimulated.beforeEnterResult
        ])
      },
      {
        path: 'resultado/simulador',
        name: NAME_ROUTES_SIMULATION.SIMULATOR_RESULT,
        component: () => import(
          /* webpackChunkName: "simulator-result" */ '@/pages/Simulated/pages/SimulatorResult/SimulatorResult.vue'
        ),
        meta: { title: 'Resultado da simulação do SISU' },
        beforeEnter: multiGuard([permissionFeature, permissionSimulator])
      },
      {
        path: 'resultado/simulador_enem',
        name: NAME_ROUTES_SIMULATION.SIMULATOR,
        component: () => import(
          /* webpackChunkName: "simulator" */ '@/pages/Simulated/pages/EnemSimulator/EnemSimulator.vue'
        ),
        meta: { title: 'Simulador Enem' },
        beforeEnter: multiGuard([permissionFeature, permissionSimulator])
      }
    ]
  }
];
